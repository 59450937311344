import { StyledBody } from 'baseui/card';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { styled } from 'style/ORSNNTheme';
import { GetTimeline_deal_cards } from '../__generated__/GetTimeline';
import BidCardBody from './BidCardBody';
import CarveDetailsCardBody from './CarveDetailsCardBody';
import DealDocumentsCardBody from './DealDocumentsCardBody';
import FundingScheduleDetailsCardBody from './FundingScheduleDetailsCardBody';
import FundingScheduleInputCardBody from './FundingScheduleInputCardBody';
import WireConfirmationCardBody from './WireConfirmationCardBody';
import { findLastIndex } from './utils';
import { Card } from 'features/deals/dealCards/CardWrapper';
import CounterpartyCardBody from './CounterpartyCardBody';
import { useDealRoomContext } from 'features/pages/marketplace/DealRoomContext';
import { StratCardBody } from 'features/deals/dealCards/StratsCard';
import { LoansCardBody } from 'features/deals/dealCards/LoansCard';
import { Tooltip } from 'react-tooltip';
import BiddingAndPricingController from 'features/pages/marketplace/BiddingAndPricing/BiddingAndPricingController';
import DiligenceController from 'features/deals/dealCards/Diligence/DiligenceController';
import CounterpartyInsightsBody from './CounterpartyInsightsBody';

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Timestamp = styled.span`
  font-size: 0.75rem;
  color: ${(props) => props.theme.color.grey700};
`;

const CardTitle = styled.h1`
  font-size: 13px;
  margin-bottom: 0;
`;

type CardBodyProps = {
  visible: boolean;
};
const CardBody = styled.div<CardBodyProps>`
  margin-top: ${(props) => (props.visible ? '.5rem' : '0')};
  max-height: ${(props) => (props.visible ? '100%' : '0')};
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export type EventActionCardBodyProps = {
  dealId: string;
} & GetTimeline_deal_cards;

function renderCardBody(card: GetTimeline_deal_cards, dealId: string) {
  switch (card.__typename) {
    case 'CounterpartyCard':
      return <CounterpartyCardBody dealId={dealId} {...card} />;
    case 'CounterpartyInsightsCard':
      return <CounterpartyInsightsBody dealId={dealId} />;
    case 'DealDocumentsCard':
      return <DealDocumentsCardBody dealId={dealId} {...card} />;
    case 'StratificationsCard':
      return <StratCardBody dealId={dealId} {...card} />;
    case 'LoansCard':
      return <LoansCardBody dealId={dealId} {...card} />;
    case 'CarveDetailsCard':
      return <CarveDetailsCardBody dealId={dealId} {...card} />;
    case 'DiligenceCard':
      return <DiligenceController dealId={dealId} {...card} />;
    case 'PlaceBidCard':
      return <BiddingAndPricingController dealId={dealId} {...card} />;
    case 'BidCard':
      return <BidCardBody dealId={dealId} {...card} />;
    case 'FundingScheduleInputCard':
      return <FundingScheduleInputCardBody dealId={dealId} {...card} />;
    case 'FundingScheduleDetailsCard':
      return <FundingScheduleDetailsCardBody dealId={dealId} {...card} />;
    case 'WireConfirmationCard':
      return <WireConfirmationCardBody dealId={dealId} {...card} />;
    case 'PlaceholderCard':
    case 'CutOffTapeCard':
    default:
      return <DefaultCardBody />;
  }
}

const formatLastUpdatedDate = (lastUpdatedDate: string) => {
  const lastUpdatedDateTime = DateTime.fromISO(lastUpdatedDate);
  return `${lastUpdatedDateTime.toRelativeCalendar()} @ ${
    lastUpdatedDateTime.diffNow().as('days') > -1
      ? lastUpdatedDateTime.toFormat('tt')
      : lastUpdatedDateTime.toFormat('F')
  }`;
};

type EventActionCardProps = {
  card: GetTimeline_deal_cards;
  dealId: string;
  isDefaultExpanded: boolean;
  isExpanded: boolean;
  expand: () => void;
};

const EventActionCard = ({
  card,
  dealId,
  isExpanded,
  expand,
}: EventActionCardProps): JSX.Element => {
  const lastUpdatedDate = useMemo(() => {
    if (
      card.__typename !== 'PlaceholderCard' &&
      card.last_updated_date != null
    ) {
      return formatLastUpdatedDate(card.last_updated_date);
    } else {
      return ``;
    }
  }, [card.last_updated_date, card.__typename]);

  const isPlaceholder = card.__typename === 'PlaceholderCard';
  const toolTip = isPlaceholder ? card.tooltip : '';
  const toolTipId = `${card.title}-tooltip`;

  return (
    <>
      <Card
        active={isExpanded}
        disabled={isPlaceholder}
        data-tooltip-id={toolTipId}
        onClick={() => {
          if (card.__typename !== 'PlaceholderCard') expand();
        }}
      >
        <TitleRow>
          <CardTitle>{card.title}</CardTitle>
          <div>
            <Timestamp>{lastUpdatedDate}</Timestamp>
          </div>
        </TitleRow>
        <CardBody visible={isExpanded}>{renderCardBody(card, dealId)}</CardBody>
      </Card>
      {isPlaceholder && (
        <Tooltip place="top" variant="light" id={toolTipId}>
          {toolTip}
        </Tooltip>
      )}
    </>
  );
};

const CardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: max-content;
  a {
    color: ${(props) => props.theme.color.brandMainPink};
  }
`;

const ScrollBox = styled.div`
  flex-grow: 4;
  height: 100%;
  overflow: auto;
`;

type EventActionCardsProps = {
  dealId: string;
  cards: GetTimeline_deal_cards[];
};

const EventActionCards = ({
  dealId,
  cards,
}: EventActionCardsProps): JSX.Element => {
  const lastNonPlaceholderIndex = useMemo(
    () => findLastIndex(cards, (card) => card.__typename !== 'PlaceholderCard'),
    [cards]
  );

  const { currentCard, setCurrentCard } = useDealRoomContext();

  
  return (
    <ScrollBox>
      <CardContainer>
        {cards.map((card, index) => {
          return (
            <EventActionCard
              key={`${card.title}-${index}`}
              card={card}
              dealId={dealId}
              expand={() => setCurrentCard(`${card.title}-${index}`)}
              isExpanded={`${card.title}-${index}` === currentCard}
              isDefaultExpanded={index === lastNonPlaceholderIndex}
            />
          )
        })}
      </CardContainer>
    </ScrollBox>
  );
};

const DefaultCardBody = (): JSX.Element => {
  return <StyledBody />;
};

export default EventActionCards;
