import { useState, useEffect } from 'react';
import {
  useMutation,
  PureQueryOptions,
  RefetchQueriesFunction,
} from '@apollo/client';
import {
  DeleteS3File,
  DeleteS3FileVariables,
} from 'mutation/__generated__/DeleteS3File';
import DELETE_S3_FILE_MUTATION from 'mutation/deleteS3File';
import { FileType } from '__generated__/globalTypes';
import { Spinner } from 'baseui/spinner';
import { IconTrash } from '@tabler/icons-react';
import { SvgIcon } from 'common-ui/Icons/SvgIcon/SvgIcon';
import { TemplateDocument } from './__generated__/TemplateDocument';

type RefetchQueriesType =
  | Array<string | PureQueryOptions>
  | RefetchQueriesFunction;

type TemplateProps = {
  docs: TemplateDocument[];
  label?: string;
  showDelete?: boolean;
  dealId?: string;
  refetchQueries?: RefetchQueriesType;
  onHomePage?: boolean;
};

function Templates({
  docs,
  label,
  showDelete = false,
  dealId,
  refetchQueries,
  onHomePage = false,
}: TemplateProps) {
  const [documentList, setDocumentList] = useState<TemplateDocument[]>(docs);

  const [deleteS3File, { loading }] = useMutation<
    DeleteS3File,
    DeleteS3FileVariables
  >(DELETE_S3_FILE_MUTATION, {
    refetchQueries,
  });

  useEffect(() => {
    setDocumentList(docs);
  }, [docs]);

  const handleDelete = async (fileName: string) => {
    if (showDelete && dealId) {
      try {
        await deleteS3File({
          variables: {
            parentId: dealId,
            fileType: FileType.DEAL_FILE,
            fileName,
          },
        });
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  return (
    <div className="relative">
      <div className="py-2">{label || 'TEMPLATES'}</div>
      <div className={onHomePage ? 'py-2 pr-2 max-h-[184px] overflow-y-auto' : 'grid grid-cols-2 gap-4 py-2'}>
        {documentList && documentList.map((document: TemplateDocument) => (
          <div
            key={document.id}
            className="text-slate-200 py-2 border-t border-accent-default flex items-center justify-between hover:bg-accent-muted"
          >
            <span>{document.name}</span>
            <div className="flex items-center">
              <a
                target="_blank"
                href={document.documentUrl}
                download
                className="flex items-center text-slate-200 no-underline px-2" rel="noreferrer"
              >
                <SvgIcon name="pdf" />
                <span className="pl-2 text-accent-default">View</span>
              </a>
              {onHomePage && (
                <button
                  onClick={() => handleDelete(document.name)}
                  className="flex items-center text-slate-200 no-underline px-2"
                >
                  <IconTrash size={20} />
                  <span className="pl-2 text-accent-default">Delete</span>
                </button>
              )}
              {showDelete && document.type === FileType.DEAL_FILE ? (
                <button
                  onClick={() => handleDelete(document.name)}
                  className="flex items-center text-slate-200 no-underline px-2"
                >
                  <IconTrash size={20} />
                  <span className="pl-2 text-accent-default">Delete</span>
                </button>
              ) : null}
            </div>
          </div>
        ))}
        {!documentList.length && <div className="text-slate-200">No saved templates.</div>}
      </div>
      {loading && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <Spinner size={34} />
        </div>
      )}
    </div>
  );
}

export default Templates;

