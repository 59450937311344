import { LoanTable } from 'features/drilldown/LoanDatatable/LoanTable';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { AssetClass } from '__generated__/globalTypes';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';

interface SelectedDiligenceTableProps {
  data: LoanDatatableLoan[];
  assetClass: AssetClass;
  selection: {
    selected: RowSelectionState;
    onSelectionChange: OnChangeFn<RowSelectionState>;
  };
  pagination: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
}

const SelectLoansForDiligenceTable = ({
  data,
  assetClass,
  selection,
  pagination,
}: SelectedDiligenceTableProps) => {
  return (
    <LoanTable
      data={data}
      strings={{ empty: 'No Loans Found' }}
      sorting={{
        state: pagination.queryParams.sortings,
        onSortingChanged: (newSortings) => {
          pagination.updateParams({ sortings: newSortings });
        },
      }}
      assetClass={assetClass}
      selection={selection}
      pagination={pagination}
      // Do not show the listing (POOL) column for the buyer
      omitColumnIds={['listing']}
    />
  );
};

export default SelectLoansForDiligenceTable;
