import React, { useState } from 'react';
import CashFlowModelingInput from 'features/drilldown/cashflows/CashFlowModelingInput';
import { Field, Form, Formik } from 'formik';
import { CashFlowsValidValues } from './configurations/cashflow-inputs';
import * as Yup from 'yup';
import { DefaultAssumptionType, PrepaymentAssumptionType } from '__generated__/globalTypes';

export const validationSchema = Yup.object({
  cdr: Yup.number().test(
    'cdr',
    'Value between 0-100',
    (value) => value !== undefined && value >= 0 && value <= 100
  ),
  cpr: Yup.number().test(
    'cpr',
    'Value between 0-100',
    (value) => value !== undefined && value >= 0 && value <= 100
  ),
  lsr: Yup.number().test(
    'lsr',
    'Value between -5-100',
    (value) => value !== undefined && value >= -5 && value <= 100
  ),
  daysDelay: Yup.number().test(
    'daysDelay',
    'Value >= 0',
    (value) => value !== undefined && value >= 0
  ),
  servicingRate: Yup.number().min(0).max(5).notRequired(),
});

export type CashFlowModelingInputsProps = {
  formValues: CashFlowsValidValues;
  onSubmit: (validatedFormValues: CashFlowsValidValues) => void;
  withServicingRate?: boolean;
};

export const CashFlowModelingInputs: React.FC<CashFlowModelingInputsProps> = ({
  formValues,
  onSubmit,
  withServicingRate,
}) => {
  const [cdrType, setCdrType] = useState<string>(formValues.defaultAssumptionType);
  const [cprType, setCprType] = useState<string>(formValues.prepaymentAssumptionType);

  const handleCdrTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: CashFlowsValidValues[keyof CashFlowsValidValues]) => void,
    handleSubmit: () => void
  ) => {
    const newCdrType = event.target.value as DefaultAssumptionType.CDR | DefaultAssumptionType.SDA;
    setCdrType(newCdrType);
    setFieldValue('cdr', 0);
    setFieldValue('defaultAssumptionType', newCdrType);
    handleSubmit();
  };

  const handleCprTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: CashFlowsValidValues[keyof CashFlowsValidValues]) => void,
    handleSubmit: () => void
  ) => {
    const newCprType = event.target.value as PrepaymentAssumptionType.CPR | PrepaymentAssumptionType.PSA;
    setCprType(newCprType);
    setFieldValue('cpr', 0);
    setFieldValue('prepaymentAssumptionType', newCprType);
    handleSubmit();
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue }) => (
        <Form>
          <div style={{ display: 'flex', gap: '16px', color: '#e8e9dc' }}>
            <div>
              <Field
                name='cdr'
                as={CashFlowModelingInput}
                fieldName='CDR'
                label={
                  <select
                    className='w-[100px] rounded-[4px] px-[8px] py-[4px] text-sm bg-pink-500'
                    id="cdrType"
                    value={cdrType}
                    onChange={(e) => handleCdrTypeChange(e, setFieldValue, handleSubmit)}
                  >
                    <option value="CDR">CDR</option>
                    <option value="SDA">SDA</option>
                  </select>
                }
                onBlur={handleSubmit}
              />
            </div>

            <div>
              <Field
                name='cpr'
                as={CashFlowModelingInput}
                fieldName='CPR'
                label={
                  <select
                    className='w-[100px] rounded-[4px] px-[8px] py-[4px] text-sm bg-pink-500'
                    id="cprType"
                    value={cprType}
                    onChange={(e) => handleCprTypeChange(e, setFieldValue, handleSubmit)}
                  >
                    <option value="CPR">CPR</option>
                    <option value="PSA">PSA</option>
                  </select>
                }
                onBlur={handleSubmit}
              />
            </div>

            <Field
              name="lsr"
              as={CashFlowModelingInput}
              fieldName="LSR"
              label="LSR"
              onBlur={handleSubmit}
            />

            <Field
              name="daysDelay"
              as={CashFlowModelingInput}
              fieldName="Delay"
              label="Delay"
              unit="d"
              onBlur={handleSubmit}
            />

            {withServicingRate && (
              <Field
                name="servicingRate"
                as={CashFlowModelingInput}
                fieldName="Servicing Rate"
                label="Servicing Rate"
                onBlur={handleSubmit}
              />
            )}
          </div>

          <button type="submit" style={{ display: 'none' }}></button>
        </Form>
      )}
    </Formik>
  );
};
