import { SvgIcon } from 'common-ui';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { ContentDiv } from 'common-ui/Dialog.styles';
import { TwoColumns } from 'features/deals/DealStages/EventActionCards/utils';
import iconPath from 'assets/pngs/partner-referral.png';
import subTextImage from 'assets/pngs/stratify.png';
import closeIcon from 'assets/svgs/close-gray.svg';
import { styled } from 'style/ORSNNTheme';
import {
  typographyHeebo24,
  typographyMonoParagraphSmall,
  typographyParagraphSmallPrimaryRegular,
} from 'common-ui/typography';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'baseui/button';
import * as Yup from 'yup';

import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

const SUBMIT_REFERRAL_MUTATION = gql`
  mutation SubmitReferral($input: SubmitReferralInput!) {
    submitReferral(input: $input)
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  institution: Yup.string().required('Institution is required'),
  emailAddress: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phone: Yup.string().required('Phone is required'),
});

const ReferralLink = () => {
  const referralDialog = useDialog();

  const [submitReferral] = useMutation(SUBMIT_REFERRAL_MUTATION);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <div>
      <ReferralLinkButton
        onClick={() => {
          setErrorMessage(null);
          referralDialog.setIsOpen(true);
        }}
      >
        Refer A Colleague
      </ReferralLinkButton>

      {/* <RotatedButton>
        <BaseButton
          type="secondary"
          label="Cancel"
          size="medium"
          onClick={handleClickOpen}
        >
          Refer A Colleague
        </BaseButton>
      </RotatedButton> */}

      <Dialog dialog={referralDialog} contentComponent={ReferralContentDiv}>
        <CloseButton onClick={() => referralDialog.closeDialog()} />
        <TwoColumns style={{ gap: 70 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: 320,
            }}
          >
            <img
              src={iconPath}
              width={233}
              height={232}
              alt="refer a colleague"
            />
            <img src={subTextImage} width={348} height={74} alt="sub text" />
            <Title>
              Refer colleagues to buy and sell loans on the ORSNN platform.
            </Title>
          </div>
          <div>
            <Title style={{ textAlign: 'left' }}>Referral Link & Code</Title>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                emailAddress: '',
                phone: '',
                institution: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                submitReferral({
                  variables: {
                    input: {
                      firstname: values.firstName,
                      lastname: values.lastName,
                      email: values.emailAddress,
                      phone: values.phone,
                      company: values.institution,
                    },
                  },
                })
                  .then(({ data }) => {
                    if (data.submitReferral) {
                      // The referral was successfully submitted
                      console.log('Referral submitted');
                      referralDialog.closeDialog();
                    } else {
                      // The referral was not successfully submitted
                      console.error('failed to submit');
                      setErrorMessage(
                        'An error occurred, please try again later.'
                      );
                    }
                  })
                  .catch((error) => {
                    // An error occurred while submitting the referral
                    console.error('error: ' + error.message);
                    // Set the error message
                    setErrorMessage(
                      'An error occurred, please try again later.'
                    );
                  });
              }}
            >
              <Form>
                <TwoColumns>
                  <FormRow>
                    <div>
                      <label htmlFor="firstName">First Name *</label>
                      <Field id="firstName" name="firstName" />
                      <CustomErrorMessage name="firstName" />
                    </div>
                  </FormRow>

                  <FormRow>
                    <label htmlFor="lastName">Last Name *</label>
                    <Field id="lastName" name="lastName" />
                    <CustomErrorMessage name="lastName" />
                  </FormRow>
                </TwoColumns>

                <FormRow>
                  <label htmlFor="institution">Institution *</label>
                  <Field id="institution" name="institution" />
                  <CustomErrorMessage name="institution" />
                </FormRow>

                <FormRow>
                  <div>
                    <label htmlFor="emailAddress">Email Address *</label>
                    <Field id="emailAddress" name="emailAddress" />
                    <CustomErrorMessage name="emailAddress" />
                  </div>
                </FormRow>

                <FormRow>
                  <div>
                    <label htmlFor="phone">Phone *</label>
                    <Field id="phone" name="phone" />
                    <CustomErrorMessage name="phone" />
                  </div>
                </FormRow>

                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button size="compact" type="submit">
                    Send Referral Invitation
                  </Button>
                </div>

                {errorMessage && (
                  <div style={{ paddingTop: 30, textAlign: 'left' }}>
                    {errorMessage}
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        </TwoColumns>
      </Dialog>
    </div>
  );
};

const ReferralLinkButton = styled.button`
  transform: rotate(-90deg);
  position: fixed;
  right: -47px;
  top: 130px;
  background: #1f1f1f;
  color: ${(props) => props.theme.color.accentEmphasis};
  padding: 12px;
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  border-radius: 4px 4px 0 0;
  font-family: 'Heebo', sans-serif;
  z-index: 1000;

  &:hover {
    color: ${(props) => props.theme.color.accentDefault};
  }

  cursor: pointer;
`;

const Title = styled.div`
  ${typographyHeebo24}
  margin-bottom: 10px;
`;

const ReferralContentDiv = styled(ContentDiv)`
  background-color: #131316;
  border: 1px solid ${(props) => props.theme.color.accentEmphasis};
  border-radius: 8px;
  padding: 60px 40px 40px;
  position: relative;
`;

const FormRow = styled.div`
  width: 100%;
  label {
    display: block;
    text-align: left;
    ${typographyParagraphSmallPrimaryRegular}
    color: ${(props) => props.theme.color.slate200};
  }
  input {
    width: 100%;
    background-color: #1f1f1f;
    border: 1px solid #333;
    padding: 7px 0px 7px 16px;
    border-radius: 4px;
    color: #fff;
    ${typographyMonoParagraphSmall}
  }
  margin-bottom: 20px;
  button {
    margin-top: 15px;
  }
`;

export default ReferralLink;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-image: url(${closeIcon});
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CustomErrorMessage = ({ name }: { name: string }) => (
  <ErrorMessage name={name}>
    {(errorMessage) => (
      <div
        style={{
          color: '#e95c7b',
          fontSize: '11px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ position: 'relative', left: '-2px', top: '1px' }}>
          <SvgIcon name="Icon-error" />
        </div>
        {errorMessage}
      </div>
    )}
  </ErrorMessage>
);
