import { ListingField } from '__generated__/globalTypes';
import { formatCurrency, formatPercent } from 'configs/columns';
import { BaseFiltersConfig } from 'features/common-elements';
import {invert } from 'lodash';
import { ASSET_CLASS_IDS } from 'models/AssetClass';
import { SIDE_NAV_CONFIG } from 'features/pages/portfolio/Portfolio/SideNav/SideNav.config';
import { GetMarketplaceListings_listings as Listing } from './__generated__/GetMarketplaceListings';
const NO_DATA = '-';

type ListingColConfig<K extends keyof Listing> = {
  string: string;
  sortable: boolean;
  numeric: boolean;
  display: (value: Listing[K]) => JSX.Element | string;
};

export const listingColsConfig = {
  [ListingField.provided_name]: {
    string: 'Provided Name',
    sortable: false,
    numeric: false,
    display: (value: string | null) => value ?? ``,
  },
  [ListingField.t_current_balance_cents]: {
    string: 'UPB',
    sortable: false,
    numeric: true,
    display: (value: string | number | Record<string, unknown> | null) => formatCurrency(value),
  },
  [ListingField.asset_class]: {
    string: 'Asset Class',
    sortable: false,
    numeric: false,
    display: (value: string | JSX.Element) => value,
  },
  [ListingField.loan_count]: {
    string: '# LOANS',
    sortable: false,
    numeric: true,
    display: (value: number | null) => value?.toLocaleString() ?? NO_DATA,
  },
  [ListingField.wa_loan_age_months]: {
    string: 'WALA',
    sortable: false,
    numeric: true,
    display: (value: number | null) => value?.toLocaleString() ?? NO_DATA,
  },
  [ListingField.wa_remaining_loan_terms_months]: {
    string: 'WAM',
    sortable: false,
    numeric: true,
    display: (value: number | null) => value?.toLocaleString() ?? NO_DATA,
  },
  [ListingField.wa_coupon]: {
    string: 'GWAC',
    sortable: false,
    numeric: true,
    display: (value: number | null ) => formatPercent(value ?? 0, 3),
  },
  [ListingField.wa_borrower_credit_score]: {
    string: 'FICO',
    sortable: false,
    numeric: true,
    display: (value: number | null) => value?.toString() ?? '-',
  },
  [ListingField.wa_ltv]: {
    string: 'LTV',
    sortable: false,
    numeric: true,
    display: (value: number | null) => `${((value ?? 0) * 100).toFixed(2)}%`,
  },
  [ListingField.wa_dti]: {
    string: 'DTI',
    sortable: false,
    numeric: true,
    display: (value: number | null) => ((value ?? 0) * 100).toFixed(2),
  },
} satisfies { [key in keyof Listing]?: ListingColConfig<key>};

export type ListingKey = keyof typeof listingColsConfig;

export const listingColsOrder: ListingKey[] = [ListingField.provided_name, ListingField.asset_class, 
  ListingField.t_current_balance_cents, ListingField.loan_count, ListingField.wa_loan_age_months, ListingField.wa_remaining_loan_terms_months,
  ListingField.wa_coupon, ListingField.wa_borrower_credit_score, ListingField.wa_ltv, ListingField.wa_dti
];

export const marketplaceFiltersConfig = {
  [ListingField.has_cra]: {
    type: 'boolean',
    displayName: 'CRA',
    falseyLabel: 'Without CRA',
    truthyLabel: 'With CRA',
  },
  [ListingField.t_current_balance_cents]: {
    type: 'range',
    displayName: `total ${listingColsConfig[ListingField.t_current_balance_cents].string} (M)`,
    min: 0,
    max: 1000,
    prepare: (value: number) => value * 100 * 1000000,
  },
  [ListingField.wa_borrower_credit_score]: {
    type: 'range',
    displayName: listingColsConfig[ListingField.wa_borrower_credit_score].string,
    min: 300,
    max: 850,
  },
  [ListingField.wa_coupon]: {
    type: 'range',
    displayName: listingColsConfig[ListingField.wa_coupon].string,
    min: 0,
    max: 25,
    prepare: (value: number) => value / 100,
  },
  [ListingField.wa_remaining_loan_terms_months]: {
    type: 'range',
    displayName: listingColsConfig[ListingField.wa_remaining_loan_terms_months].string,
    min: 0,
    max: 360,
  },
  [ListingField.wa_loan_age_months]: {
    type: 'range',
    displayName: listingColsConfig[ListingField.wa_loan_age_months].string,
    min: 0,
    max: 360,
  },
  [ListingField.asset_class]:{
    type: 'select-single',
    displayName: listingColsConfig[ListingField.asset_class].string,
    //TODO: we should get it from a more reasonable place
    filterOptions: SIDE_NAV_CONFIG
      .filter(val => !val.disabled && ASSET_CLASS_IDS[val.routeSegment] != null)
      .map(val => ({ value: ASSET_CLASS_IDS[val.routeSegment] as string, label: val.text })),
    getOptionLabel: (option) => typeof option === 'string' ? option : option.label,
    getOptionValue: (option) => typeof option === 'string' ? option : option.value,
    valueToString: (option) => typeof option === 'string' ? option : (option?.label ?? ''),
  }
} as const satisfies BaseFiltersConfig;

export const filterKeyToAbbr: {[key in keyof typeof marketplaceFiltersConfig]: string} = {
  [ListingField.t_current_balance_cents]: 'UPB',
  [ListingField.wa_borrower_credit_score]: 'FICO',
  [ListingField.wa_coupon]: 'GWAC',
  [ListingField.wa_remaining_loan_terms_months]: 'WAM',
  [ListingField.wa_loan_age_months]: 'WALA',
  [ListingField.asset_class]: 'AC',
  [ListingField.has_cra]: 'CRA',
  // [ListingField.servicing_retained]: 'SR',
};

export const abbrToFilterKey = invert(filterKeyToAbbr) as { [key: string]: ListingField };
