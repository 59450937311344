import { styled } from 'style/ORSNNTheme';
import { brochureLinkStyle } from './styles';

const Footer = styled.footer`
  font-family: 'Heebo', sans-serif;
  width: 1340px;
  position: fixed;
  bottom: 0;
  height: 100px;
`;

const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Disclosure = styled.span`
  color: ${(props) => props.theme.color.grey700};
`;

const FooterList = styled.ul`
  text-align: right;
  margin-bottom: 0;
`;

const ListItem = styled.li`
  display: inline-block;
  margin: 0px 15px;
  position: relative;

  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }

  ::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 12px;
    display: block;
    background-color: ${(props) => props.theme.color.grey700};
    right: -18px;
    top: 4px;
  }

  :last-child:after {
    content: none;
  }
`;

const FooterBreak = styled.hr`
  margin: 15px 0px;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.color.grey700};
  opacity: 0.5;
`;

const StyledLink = styled.a.attrs({ target: '_blank', rel: 'nofollow' })`
  ${brochureLinkStyle}
  color: ${(props) => props.theme.color.grey700};
  font-size: 14px;
  line-height: 20px;
  font-weight: 200;
  border-bottom: 1px solid transparent;
  display: inline-block;
  &:hover,
  &:focus {
    color: ${(props) => props.theme.color.fgDefault};
  }
`;

const CopyrightRow = styled.div`
  color: ${(props) => props.theme.color.grey700};
  font-size: 14px;
  line-height: 20px;
`;

const FooterMenu = (): JSX.Element => {
  return (
    <Footer>
      <ListContainer>
        <Disclosure>
          Disclosure: ORSNN does not transact in securities of any kind.
        </Disclosure>
        <FooterList>
          <ListItem>
            <StyledLink href={`https://orsnn.com/contact-us/`}>
              Contact
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink href="https://www.facebook.com/ORSNN">
              Facebook
            </StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink href="https://twitter.com/orsnninc">Twitter</StyledLink>
          </ListItem>
          <ListItem>
            <StyledLink href="https://www.linkedin.com/company/orsnn/">
              LinkedIn
            </StyledLink>
          </ListItem>
        </FooterList>
      </ListContainer>
      <FooterBreak />
      <CopyrightRow>
        <p>&copy; Copyright 2020-2024 ORSNN, Inc.</p>
      </CopyrightRow>
    </Footer>
  );
};

export default FooterMenu;
