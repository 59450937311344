import React, {
  MutableRefObject,
  UIEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import { Analytics } from 'aws-amplify';

import styled from 'styled-components';
import { MessageStream, MessageStreamHandle } from './MessageStream';
import { TextInput } from './TextInput';
import {
  ApolloError,
  NetworkStatus,
  QueryResult,
  useMutation,
  useQuery,
} from '@apollo/client';
import { ADD_DEAL_MESSAGE, LIST_DEAL_MESSAGES } from './fragments';
import {
  AddDealMessage,
  AddDealMessageVariables,
} from './__generated__/AddDealMessage';
import {
  ListDealMessages,
  ListDealMessagesVariables,
  ListDealMessages_dealMessages_entities,
} from './__generated__/ListDealMessages';
import { isNotNullOrUndefined } from 'functions/typeUtils';
import { DealStatus, SortDirection } from '__generated__/globalTypes';
import SVG from 'assets/svgs/svg';
import { GET_DEAL_TIMELINE } from '../DealStages/fragments';
import {
  GetTimeline,
  GetTimelineVariables,
} from '../DealStages/__generated__/GetTimeline';
import { useQueryWithPollingWhileVisible } from 'functions/useQueryWithPollingWhileVisible';
import { useDebouncedCallback } from 'use-debounce';

const SCROLL_BOUNDARY_THRESHOLD = 65;
const PAGE_SIZE = 15;

const SpacedEvenly = styled.div<{ $isError: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${($isError) => ($isError ? '12px' : '37px')};
  height: 100%;
`;

const ErrorMessage = styled.div`
  background-color: #df1642;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  color: #ffffff;
  gap: 20px;
  min-height: max-content;
`;

interface MessagingProps {
  dealId: string;
}

const basePagination = {
  limit: PAGE_SIZE,
  sort_direction: SortDirection.desc,
};

export const Messaging: VFC<MessagingProps> = ({ dealId }) => {
  const [inputValue, setInputValue] = useState('');
  const [intervals, setIntervals] = useState<number[]>([]);

  const {
    error,
    data,
    refetch: unwrappedRefetch,
    fetchMore: unwrappedFetchMore,
    networkStatus,
  } = useQuery<ListDealMessages, ListDealMessagesVariables>(
    LIST_DEAL_MESSAGES,
    {
      variables: {
        deal_id: dealId,
        pagination: basePagination,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onError: (error: ApolloError) => {
        let statusCode;
        let stringifiedError = '';
        if (error.networkError && 'statusCode' in error.networkError) {
          statusCode = error.networkError.statusCode;
        }

        try {
          stringifiedError = JSON.stringify(error);
        } catch (_e) {
          // continue
        }

        Analytics.record({
          name: 'dealRoomChat_fetchError',
          attributes: {
            error: stringifiedError,
            message: error.message,
            httpCode: statusCode,
          },
        });
      },
    }
  );

  // Getting timeline from cache only, mainly to get the deal status
  // it's fine to get it from the cache only because DealStages/index.tsx is
  // polling GetTimeline every second
  const { stopPolling, data: timelineData } = useQueryWithPollingWhileVisible<
    GetTimeline,
    GetTimelineVariables
  >(GET_DEAL_TIMELINE, {
    variables: { id: dealId },
    fetchPolicy: 'cache-only',
    pollInterval: 5000,
  });

  const isDealComplete = useMemo(() => {
    const isComplete = DealStatus.COMPLETE === timelineData?.deal?.state.status;
    if (isComplete) {
      stopPolling();
    }
    return isComplete;
  }, [stopPolling, timelineData?.deal?.state.status]);

  const messages = useMemo<
    ListDealMessages_dealMessages_entities[] | undefined
  >(() => {
    return data?.dealMessages?.entities?.filter(isNotNullOrUndefined);
  }, [data]);

  const {
    scrollBox,
    refetch: autoScrollRefetch,
    fetchMore,
    messageStreamRef,
  } = useAutoScrolling(unwrappedRefetch, unwrappedFetchMore, messages);

  const [sendMessage] = useMutation<AddDealMessage, AddDealMessageVariables>(
    ADD_DEAL_MESSAGE,
    { onCompleted: () => autoScrollRefetch() }
  );

  const handleSubmit = (message: string) => {
    sendMessage({
      variables: {
        input: {
          deal_id: dealId,
          message,
        },
      },
    });
  };

  const handleScrollCb = useCallback(
    async (e: UIEvent<HTMLDivElement>) => {
      if (!messages) {
        return;
      }

      if (
        (e?.target as HTMLDivElement).scrollTop < SCROLL_BOUNDARY_THRESHOLD &&
        messages.length >= PAGE_SIZE
      ) {
        const lastMessageFetched = data?.dealMessages?.entities?.[0];

        if (!lastMessageFetched) {
          return;
        }

        const { id, created_time } = lastMessageFetched;

        await fetchMore({
          variables: {
            pagination: {
              ...basePagination,
              last_evaluated_key: { id, created_time, deal_id: dealId },
            },
          },
        });
      }
    },
    [data, messages, fetchMore, dealId]
  );

  const handleScroll = useDebouncedCallback(handleScrollCb, 500, {
    leading: true,
    trailing: true,
  });

  const isError = Boolean(error);

  const { visibilityState } = document;

  useEffect(() => {
    if (visibilityState === 'hidden') {
      // do not restart polling when tab is hidden
      return;
    }
    // Not using Apollo's polling feature so that we can measure
    // scroll position before refreshing data. If user has scroll
    // set to show the latest message, auto scroll when new
    // messages are fetched.

    let POLLING_INTERVAL = 2000;

    if (isError) {
      POLLING_INTERVAL = POLLING_INTERVAL * 10;
    }

    const intervalId = window.setInterval(() => {
      autoScrollRefetch();
    }, POLLING_INTERVAL);
    setIntervals((intervals) => [...intervals, intervalId]);

    // stop polling when the deal is complete
    if (isDealComplete) {
      window.clearInterval(intervalId);
    }
  }, [
    autoScrollRefetch,
    isError,
    isDealComplete,
    setIntervals,
    visibilityState,
  ]);

  useEffect(() => {
    if (visibilityState === 'hidden' && intervals.length > 0) {
      intervals.forEach((intervalId) => window.clearInterval(intervalId));
      setIntervals([]);
    }
    return () => {
      intervals.forEach((intervalId) => window.clearInterval(intervalId));
    };
  }, [visibilityState, intervals, setIntervals]);

  const isLoading = useMemo(
    () => networkStatus === NetworkStatus.fetchMore,
    [networkStatus]
  );

  return (
    <div className='container relative p-[2px] pb-5 bg-background-canvas h-full overflow-auto border border-solid border-[#2b2b2b]'>
      <SpacedEvenly $isError={isError}>
        <div className='overflow-y-auto px-3 pt-3' ref={scrollBox} onScroll={handleScroll}>
          <MessageStream
            isLoading={isLoading}
            messages={messages}
            ref={messageStreamRef}
          />
        </div>

        {error && (
          <ErrorMessage title={error.message}>
            <SVG
              name="error-outline"
              fill="#FFFFFF"
              width="24px"
              height="24px"
            />
            An error occured. Please try again later.
          </ErrorMessage>
        )}
        {/* only allow to add more message when deal is not complete */}
        <TextInput
          value={inputValue}
          onChange={setInputValue}
          onSubmit={handleSubmit}
          disabled={isError || isDealComplete}
          placeholderText={
            isDealComplete
              ? 'Messaging is disabled for completed Deal'
              : undefined
          }
        />
      </SpacedEvenly>
    </div>
  );
};

type FetchMoreParams = Parameters<QueryResult['fetchMore']>;

function useAutoScrolling(
  unwrappedRefetch: QueryResult['refetch'],
  unwrappedFetchMore: QueryResult['fetchMore'],
  messages?: ListDealMessages_dealMessages_entities[]
): {
  scrollBox: React.MutableRefObject<HTMLDivElement | null>;
  refetch: () => void;
  fetchMore: (...args: FetchMoreParams) => ReturnType<QueryResult['fetchMore']>;
  messageStreamRef: React.MutableRefObject<MessageStreamHandle | null>;
} {
  const scrollBox = useRef<HTMLDivElement>(null);
  const messageStreamRef = useRef<MessageStreamHandle | null>(null);
  const scrollToElRef = useRef<HTMLDivElement | null>(null);
  const shouldAutoScrollRef = useRef(true);

  useEffect(() => {
    if (!messages) {
      return;
    }
    if (scrollBox.current) {
      maybeScrollToLatestMessage(scrollBox.current, shouldAutoScrollRef);

      if (scrollToElRef.current) {
        maybeScrollToPreviouslyViewedMessage(
          scrollBox.current,
          scrollToElRef.current
        );
      }
    }
  }, [messages]);

  const checkScrollPositionThenRefetch = useCallback(() => {
    const el = scrollBox.current;
    if (el === null) {
      return;
    }
    const scrollablePixels = el.scrollHeight - el.clientHeight;

    if (el.scrollTop + SCROLL_BOUNDARY_THRESHOLD > scrollablePixels) {
      shouldAutoScrollRef.current = true;
    } else {
      shouldAutoScrollRef.current = false;
    }
    unwrappedRefetch();
  }, [unwrappedRefetch]);

  const fetchMore = useCallback(
    async (...args: Parameters<QueryResult['fetchMore']>) => {
      scrollToElRef.current =
        messageStreamRef.current?.getFirstMessageElement() || null;

      return unwrappedFetchMore(...args);
    },
    [unwrappedFetchMore]
  );

  return {
    scrollBox,
    refetch: checkScrollPositionThenRefetch,
    fetchMore,
    messageStreamRef,
  };
}

function maybeScrollToLatestMessage(
  scrollBox: HTMLDivElement,
  shouldAutoScroll: MutableRefObject<boolean>
) {
  const scrollablePixels = scrollBox.scrollHeight - scrollBox.clientHeight;

  if (shouldAutoScroll.current) {
    scrollBox.scrollTop = scrollablePixels;
    shouldAutoScroll.current = false;
  }
}

function maybeScrollToPreviouslyViewedMessage(
  scrollBox: HTMLDivElement,
  previouslyViewedMessageEl: HTMLDivElement
) {
  const scrollPosition = scrollBox.scrollTop;

  if (scrollPosition < SCROLL_BOUNDARY_THRESHOLD) {
    previouslyViewedMessageEl.scrollIntoView();
    const html = document.querySelector('html');
    if (html) {
      html.scrollTop = 0;
    }
  }
}
