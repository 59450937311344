import { gql, useQuery } from '@apollo/client';
import { Spinner } from 'common-ui';
import Form5300Analysis from 'features/deals/dealCards/form5300/Form5300Analysis';
import { Form5300AnalysisFragment } from './fragments';

const GET_DEAL_COUNTERPARTY_INFO_QUERY = gql`
  query GetDealCounterparty($id: ID!) {
    deal(id: $id) {
      id
      counterparty {
        ...Form5300Analysis
      }
    }
  }
  ${Form5300AnalysisFragment}
`;

type CounterpartyInsightsBodyProps = {
  dealId: string;
};

function CounterpartyInsightsBody({ dealId }: CounterpartyInsightsBodyProps) {
  const { loading, data, error, refetch } = useQuery(GET_DEAL_COUNTERPARTY_INFO_QUERY, {
    variables: { id: dealId },
    onError: (error) => {
      console.error('Error loading deal counterparty info:', error);
      refetch();
    },
  });

  if (loading) {
    return (
      <div>
        <Spinner loading={loading} positionRelative />
        <p>Wait times up to 15 seconds are possible as we are doing LLM analysis of the Counterparty</p>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <p>Error loading data</p>
      </div>
    );
  }

  const counterparty = data?.deal.counterparty;

  return (
    <>
      {counterparty && counterparty.__typename === 'PrivateCompany' && (
        <Form5300Analysis data={counterparty.form5300LLMAnalysis} />
      )}
    </>
  );
}

export default CounterpartyInsightsBody;
