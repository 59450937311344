import { FC, useState, useEffect } from 'react';
import { useQuery as useReactQuery } from '@tanstack/react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Label } from 'recharts';
import { QUERY_KEYS } from '../shared';
import { ChartSkeletonLoader } from './ChartSkeleton';
import { Dropdown } from 'common-ui/Dropdown/Dropdown';
import { CustomTooltip } from './CustomTooltip';
import { MultiValue, SingleValue } from 'react-select';

type SofrYieldsData = {
  'SOFR Par Yields': {
    labels: string[];
    series: {
      [key: string]: number[];
    };
  };
};

type DataPoint = {
  date: string;
  [key: string]: string | number;
};

type SofrYieldsProps = {
  dataUrl?: string;
  width?: number;
  height?: number;
  className?: string;
};

const colors = ['#e75ee4', '#3ccece', '#8490ec'];

interface Option {
  label: string;
  value: string;
}

function isMultiValue<T>(value: SingleValue<T> | MultiValue<T>): value is MultiValue<T> {
  return Array.isArray(value);
}

export const SofrYields: FC<SofrYieldsProps> = ({
  dataUrl,
  width = 620,
  height = 382,
  className = '',
}) => {
  const { data, isLoading } = useReactQuery<SofrYieldsData, Error, DataPoint[]>({
    queryKey: [QUERY_KEYS.SOFR_YIELDS, dataUrl],
    queryFn: async () => {
      if (!dataUrl) throw new Error('No URL provided');
      const response = await fetch(dataUrl);
      return response.json();
    },
    enabled: !!dataUrl,
    select: (data): DataPoint[] => {
      const allData: DataPoint[] = [];
      data['SOFR Par Yields'].labels.forEach((label, index) => {
        const dataPoint: DataPoint = { date: label };
        Object.keys(data['SOFR Par Yields'].series).forEach((key) => {
          dataPoint[key] = data['SOFR Par Yields'].series[key][index];
        });
        allData.push(dataPoint);
      });
      return allData;
    },
  });

  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [filteredData, setFilteredData] = useState<DataPoint[]>([]);

  useEffect(() => {
    if (data) {
      const seriesNames = Object.keys(data[0]).filter((key) => key !== 'date');
      setSelectedSeries(seriesNames[0]);
    }
  }, [data]);

  useEffect(() => {
    if (data && selectedSeries) {
      setFilteredData(data.map((d) => ({ date: d.date, [selectedSeries]: d[selectedSeries] })));
    }
  }, [data, selectedSeries]);

  const handleSeriesChange = (selectedOption: MultiValue<Option> | SingleValue<Option>) => {
    if (selectedOption == null || isMultiValue(selectedOption)) {
      return;
    }
    setSelectedSeries(selectedOption.value);
  };

  const seriesNames = data ? Object.keys(data[0]).filter((key) => key !== 'date') : [];
  const categoryOptions = seriesNames.map((seriesName) => ({
    value: seriesName,
    label: seriesName,
  }));

  if (isLoading || !data) {
    return <ChartSkeletonLoader width={width} height={height} />;
  }

  // Calculate the max and min data values
  const maxDataValue = Math.max(...data.flatMap(point => seriesNames.map(name => point[name] as number)));
  const minDataValue = Math.min(...data.flatMap(point => seriesNames.map(name => point[name] as number)));
  
  // Calculate the Y-axis ticks and domain
  const axisTop = Math.ceil(maxDataValue);
  const axisBottom = Math.floor(minDataValue);
  const yAxisTicks = Array.from({ length: axisTop - axisBottom }, (_, i) => axisBottom + i);

  return (
    <div className={`bg-background-surface rounded-lg px-4 py-3 mx-auto mt-16 w-fit relative ${className}`}>
      <div className='flex justify-center'>
        <Dropdown
          className='w-[120px] ml-2 mb-4'
          options={categoryOptions}
          onChange={handleSeriesChange}
          placeholder='Select Series'
          value={categoryOptions.find(option => option.value === selectedSeries)}
        />
      </div>
      <div className="relative">
        <div
          className="absolute inset-0"
          style={{
            background: 'radial-gradient(ellipse at center, rgba(60,206,206,0.2) 0%, rgba(255,0,210,0) 70%)',
            opacity: 0.4,
          }}
        />
        <LineChart
          width={width}
          height={height}
          data={filteredData}
        >
          <XAxis
            dataKey="date"
            stroke="#BBC5D7"
            tickMargin={16}
          >
            <Label
              value="Date"
              offset={10}
              position="bottom"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
            />
          </XAxis>
          <YAxis
            domain={[axisBottom, axisTop]}
            stroke="#BBC5D7"
            padding={{ top: 0, bottom: 10 }}
            tickMargin={10}
            ticks={yAxisTicks}
          >
            <Label
              value="Yield %"
              angle={-90}
              position="insideLeft"
              style={{ fill: '#BBC5D7', fontSize: 12 }}
              offset={10}
            />
          </YAxis>
          <CartesianGrid stroke="#28303E" />
          <Tooltip content={<CustomTooltip />} />
          {selectedSeries && (
            <Line
              type="linear"
              dataKey={selectedSeries}
              stroke={colors[0]}
              dot={false}
              strokeWidth={2}
              filter="drop-shadow(0px 0px 2px rgba(132, 144, 236, 0.53))"
            />
          )}
        </LineChart>
      </div>
    </div>
  );
};
