import { useQuery } from '@apollo/client';
import { GET_DEAL_GENERAL_INFO_QUERY } from 'features/deals/dealCards/gql/dealDetailsQueries';
import {
  GetDealGeneralInfo,
  GetDealGeneralInfoVariables,
} from 'features/deals/dealCards/gql/__generated__/GetDealGeneralInfo';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DealStages from 'features/deals/DealStages';
import { Messaging } from 'features/deals/Messaging/Messaging';
import StatusTracker from './StatusTracker';
import DealRoomContext from './DealRoomContext';

export function DealRoom() {
  const { deal_id: dealId } = useParams<{ deal_id: string }>();

  const { data } = useQuery<
    GetDealGeneralInfo,
    GetDealGeneralInfoVariables
  >(GET_DEAL_GENERAL_INFO_QUERY, {
    variables: { id: dealId as string },
    skip: !dealId,
  }); // we cannot conditionally useQuery so we skip it

  const [currentCard, setCurrentCard] = useState<string>('Counterparty Detail');

  if (dealId == null) {
    return <>Missing Deal Id</>;
  }
  const assetClass = data?.deal?.listing.assetClass;

  const doSetCurrentCard = (card: string) => {
    setCurrentCard(card);
  };

  return (
    <DealRoomContext.Provider
      value={{
        dealId: dealId,
        currentCard,
        setCurrentCard: doSetCurrentCard,
      }}
    >
      <div className="min-w-[14px]" style={{ gridArea: 'sidebar' }}></div>
      {assetClass ? (
        <div className="flex flex-row gap-2 page">
          <div className="progress flex flex-col gap-1.5 sticky top-0">
            <StatusTracker deal={data.deal} />
            {data.deal?.is_chat_enabled && <Messaging dealId={dealId} /> }
          </div>
          <div className="cards flex-[0_0_75%] max-w-[75%]">{<DealStages />}</div>
        </div>
      ) : null}
    </DealRoomContext.Provider>
  );
}
