import { gql, useQuery } from '@apollo/client';
import { ChartFragments, MonthlyTableFragments, PortfolioSummaryMetrics, SummaryTableFragments } from 'features/drilldown/cashflows/gql/cashflowDataFragments';
import { Filter } from '__generated__/globalTypes';
import { CashFlowsValidValues } from 'features/drilldown/cashflows/configurations/cashflow-inputs';
import { ASSET_CLASS_IDS, AssetClassId } from 'models/AssetClass';
import { createQueryFilters } from 'context/DataContext';
import { GetCashflowByPool, GetCashflowByPoolVariables } from './__generated__/GetCashflowByPool';

export const POOL_CASHFLOW = gql`
  query GetCashflowByPool(
    $listingId: ID!
    $cdr: Float!
    $cpr: Float!
    $lsr: Float!
    $daysDelay: Int
    $filters: [Filter!]
    $marketYield: Float!
  ) {
    userCompanyListing(id: $listingId) {
      id
      performance_summary(filters: $filters) {
          cashFlows: cash_flows(
          conditionalDefaultRate: $cdr
          conditionalPrepaymentRate: $cpr
          lossSeverityRate: $lsr
          daysDelay: $daysDelay
          marketYield: $marketYield
        ) {
          monthlyCashFlows: monthly_cash_flows {
            ...ChartCashFlowMonth
            ...MonthlyTableCashFlowMonth
          }
          totals {
            ...SummaryTableCashFlowTotals
          }
        }
        ...PortfolioSummaryMetrics
        ...SummaryTablePerformanceSummary
      }
    }
  }
  ${PortfolioSummaryMetrics.performanceSummaryMetrics}
  ${MonthlyTableFragments.cashFlowMonth}
  ${ChartFragments.cashFlowMonth}
  ${SummaryTableFragments.cashFlowTotals}
  ${SummaryTableFragments.performanceSummary}
`;

export const getUseSelectedPoolCashflow = (assetClassId: AssetClassId, filters: Filter[]) => {
  const assetClass = ASSET_CLASS_IDS[assetClassId];

  const useSelectedPoolCashflow_gql = (listingId: string, userInputs: CashFlowsValidValues) => {
    const { loading, error, data } = useQuery<GetCashflowByPool, GetCashflowByPoolVariables>(POOL_CASHFLOW, {
      variables: {
        cdr: userInputs.cdr / 100,
        cpr: userInputs.cpr / 100,
        lsr: userInputs.lsr / 100,
        daysDelay: userInputs.daysDelay,
        listingId,
        filters: createQueryFilters(assetClass, filters),
        marketYield: 3.5 / 100,
      },
      skip: !listingId,
    });

    return { loading, error, data: data?.userCompanyListing?.performance_summary };
  };

  return useSelectedPoolCashflow_gql;
};
