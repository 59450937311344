import React, { ReactNode } from 'react';
import classNames from 'classnames';

export const CarveName: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <label className="flex items-center gap-2 p-2 h-10 text-white font-heebo text-base">
      {children}
    </label>
  );
};

export const CarveDetailsContainer: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex flex-col text-slate-200 text-base font-heebo">
      {children}
    </div>
  );
};

export const CarveDetailsRow: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex justify-between border-b border-slate-600 odd:bg-slate-800">
    {children}
  </div>
);

export const CarveDetailsLabel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="p-1.5 px-3 w-full sm:w-2/5 border-r border-slate-600 text-left capitalize pr-3">
    {children}
  </div>
);

export const CarveDetailsValue: React.FC<{ children: React.ReactNode; fullWidth?: boolean }> = ({ children, fullWidth = false }) => (
  <div className={`${fullWidth ? 'w-full text-left' : 'w-full sm:w-3/5 text-right'} p-1.5 px-3`}>
    {children}
  </div>
);

export const Subheader: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="text-sm font-heebo text-grey-400">
      {children}
    </div>
  );
};

export const CarveSubheader: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex h-8 items-center text-grey-400 gap-2 px-2 pb-2 text-sm font-heebo text-base">
      {children}
    </div>
  );
};

export const CarveActions: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex gap-4 mb-5">
      {children}
    </div>
  );
};

export const CarveCardContainer: React.FC<{ highlight?: boolean; children: ReactNode }> = ({ children, highlight = false }) => {
  return (
    <div
      className={classNames(
        'rounded-lg flex-grow min-w-[222px] max-w-[355px] font-heebo text-base',
        {
          'text-pink-300': highlight,
          'text-white': !highlight,
        }
      )}
    >
      {children}
    </div>
  );
};
