import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { DataDisplayTable, DataDisplayTableProps } from 'common-ui';
import { GetMarketplaceListings_listings as ListingType } from './__generated__/GetMarketplaceListings';
import { listingColsConfig, listingColsOrder } from './marketplace.config';
import DealLink from './DealLink';

const tableColumns: ColumnDef<ListingType, ValueOf<ListingType>>[] = [createColumnHelper<ListingType>().display({
  id: 'view-deal',
  cell: (props) => {
    const isMyListing = props.row.original.company.__typename === 'UserCompany';

    const listingHref =
      isMyListing
        ? '/marketplace/active_trades'
        : `../listing/${props.row.original.id}`;

    return <DealLink
      isMyListing={isMyListing}
      listingHref={listingHref}
      listingId={props.row.original.id}
      listingDetails={props.row.original}
    />;
  }
}),
...getColumns()
];

type MarketplaceTableProps = {
  data: ListingType[],
  selection?: DataDisplayTableProps<ListingType, unknown>['selection'],
}

export const MarketplaceTable: React.FC<MarketplaceTableProps> = (props) => {
  return <DataDisplayTable
    // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
    data={props.data}
    // @ts-expect-error - TS doesn't like the fact that we're omitting the listing column
    columns={tableColumns}
    noDataMessage="No Data"
    selection={props.selection}
  />;
};

type ValueOf<T> = T[keyof T];

function getColumns() {
  const columns: ColumnDef<ListingType, ValueOf<ListingType>>[] = listingColsOrder.map((col) => ({
    accessorKey: col,
    header: listingColsConfig[col].string,
    meta: {
      alignment: listingColsConfig[col].numeric ? 'right' : 'left',
      numeric: listingColsConfig[col].numeric,
    },
    cell: (props) => {
      const fieldValue = props.getValue() as ValueOf<ListingType>;
      const config = listingColsConfig[col];
      // @ts-expect-error - TS thinks display's param is never
      return config.display(fieldValue);
    }
  }));

  return columns;
}
