import { AssetClass, LoanField } from '__generated__/globalTypes';

type FieldConfig = {
  field: LoanField;
  label: string;
};

const MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.account_id, label: 'Account Id' },
  { field: LoanField.current_balance_cents, label: 'Current Balance' },
  { field: LoanField.interest_rate, label: 'Interest Rate' },
  { field: LoanField.maturity_date_seconds, label: 'Maturity Date' },
  { field: LoanField.original_balance_cents, label: 'Original Balance' },
  { field: LoanField.first_payment_date_seconds, label: 'First Payment Date' },
  { field: LoanField.state, label: 'State' },
];

const CONSUMER_MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.borrower_credit_score, label: 'Borrower Credit Score' },
  { field: LoanField.dti, label: 'Debt to Income Ratio' },
];

const SECURED_CONSUMER_MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  ...CONSUMER_MANDATORY_LOAN_FIELDS,
  { field: LoanField.ltv, label: 'LTV' },
];

const AUTO_MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.property_year, label: 'Vehicle Year' },
];

const AUTO_OPTIONAL_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.auto_vin, label: 'Vehicle VIN' },
  {
    field: LoanField.auto_original_value_cents,
    label: 'Vehicle Original Value',
  },
];

const HOME_MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.purpose, label: 'Loan Purpose' },
  { field: LoanField.occupancy, label: 'Occupancy Type' },
];

const HOME_ARM_MANDATORY_LOAN_FIELDS: FieldConfig[] = [
  {
    field: LoanField.first_rate_change_date_seconds,
    label: 'First Rate Change Date',
  },
  {
    field: LoanField.rap_months,
    label: '(RAP) Rate Adjustment Period (Months)',
  },
  { field: LoanField.periodic_cap_rate, label: 'Periodic Rate Cap' },
  { field: LoanField.lifetime_cap_rate, label: 'Lifetime Rate Cap' },
  { field: LoanField.arm_margin, label: 'ARM Margin' },
  { field: LoanField.arm_index, label: 'ARM Index' },
];

const HOME_OPTIONAL_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.property_year, label: 'Property Year' },
  { field: LoanField.cra, label: 'CRA' },
];

const HOME_ARM_OPTIONAL_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.arm_index_rate, label: 'ARM Index Rate' },
  { field: LoanField.lifetime_floor_rate, label: 'Lifetime Rate Floor' },
  {
    field: LoanField.irap_months,
    label: '(IRAP) Initial Rate Adjustment Period (Months)',
  },
  { field: LoanField.interest_only, label: 'Interest Only (I/O)' },
  { field: LoanField.io_period_months, label: 'IO Period (Months)' },
];

const OPTIONAL_LOAN_FIELDS: FieldConfig[] = [
  { field: LoanField.city, label: 'City' },
  { field: LoanField.origination_date_seconds, label: 'Origination Date' },
  { field: LoanField.next_payment_date_seconds, label: 'Next Payment Date' },
  { field: LoanField.postal_code, label: 'Postal Code' },
  { field: LoanField.product, label: 'Product' },
  { field: LoanField.property_description, label: 'Property Description' },
  { field: LoanField.servicing_rate, label: 'Servicing Rate' },
];

function configSort(configA: FieldConfig, configB: FieldConfig) {
  return configA.label.localeCompare(configB.label);
}

const FIELD_CONFIG = {
  [AssetClass.AUTO]: {
    requiredFields: [
      ...MANDATORY_LOAN_FIELDS,
      ...SECURED_CONSUMER_MANDATORY_LOAN_FIELDS,
      ...AUTO_MANDATORY_LOAN_FIELDS,
    ].sort(configSort),
    optionalLoanFields: [
      ...OPTIONAL_LOAN_FIELDS,
      ...AUTO_OPTIONAL_LOAN_FIELDS,
    ].sort(configSort),
  },
  [AssetClass.UNSECPERSONAL]: {
    requiredFields: [
      ...MANDATORY_LOAN_FIELDS,
      ...CONSUMER_MANDATORY_LOAN_FIELDS,
    ].sort(configSort),
    optionalLoanFields: [...OPTIONAL_LOAN_FIELDS].sort(configSort),
  },
  [AssetClass.HOME]: {
    requiredFields: [
      ...MANDATORY_LOAN_FIELDS,
      ...SECURED_CONSUMER_MANDATORY_LOAN_FIELDS,
      ...HOME_MANDATORY_LOAN_FIELDS,
    ].sort(configSort),
    optionalLoanFields: [
      ...OPTIONAL_LOAN_FIELDS,
      ...HOME_OPTIONAL_LOAN_FIELDS,
    ].sort(configSort),
  },
  HOME_ARM: {
    requiredFields: [
      ...MANDATORY_LOAN_FIELDS,
      ...SECURED_CONSUMER_MANDATORY_LOAN_FIELDS,
      ...HOME_MANDATORY_LOAN_FIELDS,
      ...HOME_ARM_MANDATORY_LOAN_FIELDS,
    ].sort(configSort),
    optionalLoanFields: [
      ...OPTIONAL_LOAN_FIELDS,
      ...HOME_OPTIONAL_LOAN_FIELDS,
      ...HOME_ARM_OPTIONAL_LOAN_FIELDS,
    ].sort(configSort),
  },
  [AssetClass.BOAT]: {
    requiredFields: [...MANDATORY_LOAN_FIELDS],
    optionalLoanFields: [],
  },
  [AssetClass.RV]: {
    requiredFields: [...MANDATORY_LOAN_FIELDS],
    optionalLoanFields: [],
  },
  [AssetClass.CRE]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.MBL]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.REVOLVING]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.SOLAR]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.WAREHOUSE]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.MOTORCYCLE]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.AIRCRAFT]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
  [AssetClass.MARINE]: {
    requiredFields: [],
    optionalLoanFields: [],
  },
};

export { FIELD_CONFIG };
export type { FieldConfig };
