import { styled } from 'style/ORSNNTheme';
import { Spinner } from 'common-ui';
import { FIELD_CONFIG } from 'configs/columns/index';
import { useQuery } from '@apollo/client';
import {
  GetLoanAccount,
  GetLoanAccountVariables,
} from 'query/__generated__/GetLoanAccount';
import { GET_LOAN } from 'query/loanDetails';

interface PassedProps {
  header: string;
  selectedLoanId: string;
  companyId: string;
}

const Container = styled.div`
  padding: 10px 24px;
  background: ${({ theme }) => theme.color.grey950};
  border: 1px solid ${({ theme }) => theme.color.accentMuted};
  border-radius: 4px;
  min-width: 430px;
  color: ${({ theme }) => theme.color.white};
`;

const Header = styled.div`
  padding: 8px 24px;
  fontSize: 18px; 
`;

const List = styled.ul`
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  border-top: 1px solid #32363E;
  padding: 6px 24px;
  list-style: none;
  display: flex;
  justify-content: space-between;
`;

const ListItemKey = styled.div`
  font-size: 14px;
  color: #909199;
  padding-right: 100px;
`;

const ListItemValue = styled.div`
  color: #F4F4FD;
  font-family: PT Mono;
`;

const DetailsCard = ({
  header,
  selectedLoanId,
  companyId,
}: PassedProps) => {

  const { loading, data } = useQuery<GetLoanAccount, GetLoanAccountVariables>(GET_LOAN, {
    variables: { 
      accountId: selectedLoanId as unknown as string || '',
      companyId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const loanData = data?.account;

  if (loading) {
    return <Spinner loading={loading} />;
  }

  return (
    <Container>
      <Header>{header}</Header>
      <List>
        {
          loanData && Object.keys(loanData)?.map((field) => {
            const fieldConfig = FIELD_CONFIG[field as keyof typeof FIELD_CONFIG];

            if (!fieldConfig) return null;

            const { string, display } = fieldConfig;
            const fieldValue = loanData[field as keyof typeof loanData];

            return string != null && display != null ? (
              <ListItem key={string}>
                <ListItemKey>{string}</ListItemKey>
                { //@ts-expect-error display is a function that can take this value
                  (<ListItemValue>{field === 'listing' ? loanData.listing?.name : display(fieldValue)}</ListItemValue>)
                }
              </ListItem>
            ) : null;  // return null instead of '' to be React-friendly
          })
        }
      </List>
    </Container>
  );
};

export default DetailsCard;
