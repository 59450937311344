import { getPortfolioFiltersConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import { TabContent, Row, SmallRowLabel } from './commonStyles';
import DiligencePopulationMetrics, {
  DiligencePopulationMetricsProps,
} from './DiligencePopulationMetrics';
import AssetClassConfig from 'models/AssetClass';
import { AssetClass } from '__generated__/globalTypes';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { PillFilters } from 'features/common-elements';
import { BaseButton, SvgIcon } from 'common-ui';
import { useCallback, useEffect, useState } from 'react';
import { RowSelectionState } from '@tanstack/react-table';
import SelectLoansForDiligenceTable from './SelectLoansForDiligenceTable';
import { RowId } from './DiligenceController';
import {
  generateRowId,
  parseRowId,
} from 'features/drilldown/LoanDatatable/LoanTable';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { useLoanDiligence } from './contexts/LoanDiligenceProvider';
import { ApolloError } from '@apollo/client';

export interface SelectLoansProps {
  assetClass: AssetClass;
  loans: LoanDatatableLoan[];
  totalLoans?: number;
  is_complete: boolean;
  loansError?: ApolloError;
  selectLoansMetrics: DiligencePopulationMetricsProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: TableQueryParameters<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateQueryParams: (params: Partial<TableQueryParameters<any, any>>) => void;
  selectedLoansSnapshot: RowId[];
  switchToReviewDiligence?: () => void;
}

// TODO(kentskinner): these need to be moved to a common location, or perhaps it makes sense to pass them down in QueryParameters.
export const rowsPerPageOptions = [
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const SelectLoansTab: React.FC<SelectLoansProps> = ({
  assetClass,
  loans,
  totalLoans,
  selectLoansMetrics,
  queryParams,
  updateQueryParams,
  selectedLoansSnapshot,
  switchToReviewDiligence,
  is_complete,
}) => {
  // State for keeping track all of selected loans (across all pages)
  const [selectedLoans, setSelectedLoans] = useState<RowSelectionState>({});
  const numSelected = Object.values(selectedLoans).filter((v) => v).length;

  const loanDiligence = useLoanDiligence();

  useEffect(() => {
    const newSelectedLoans: RowSelectionState = {};
    selectedLoansSnapshot.forEach((rowId) => {
      newSelectedLoans[rowId] = true;
    });
    setSelectedLoans(newSelectedLoans);
  }, [selectedLoansSnapshot]);

  // Derived values
  const portfolioFiltersConfig = assetClass
    ? getPortfolioFiltersConfig(AssetClassConfig[assetClass].id)
    : null;
  const { pageSize } = queryParams;

  // Select all loans on the current page
  const handleSelectRowsOnPage = useCallback(() => {
    setSelectedLoans((currentSelection) => {
      const newSelection = { ...currentSelection };
      loans.forEach((loan) => {
        newSelection[generateRowId(loan.account_id, loan.id)] = true;
      });
      return newSelection;
    });
  }, [loans]);

  // Mark all selected loans for diligence
  const handleSelectForDiligence = useCallback(() => {
    const loanIds = Object.keys(selectedLoans).map(
      (key) => parseRowId(key).loanId
    );
    loanDiligence.selectLoans(loanIds);
    if (switchToReviewDiligence) switchToReviewDiligence();
  }, [selectedLoans, loanDiligence, switchToReviewDiligence]);

  return (
    <TabContent>
      <Row>
        <SmallRowLabel>Diligence Population</SmallRowLabel>
        <DiligencePopulationMetrics metrics={selectLoansMetrics} />
      </Row>
      <Row>
        <div>
          {portfolioFiltersConfig && (
            <PillFilters
              filtersConfig={portfolioFiltersConfig}
              currentFilters={queryParams.filters}
              setCurrentFilters={(filters) =>
                updateQueryParams({ ...queryParams, filters })
              }
            />
          )}
        </div>
      </Row>
      {!is_complete && (
        <Row>
          <BaseButton
            type="secondary"
            size="compact"
            label="Clear all rows"
            className="py-[2px] px-[6px]"
            onClick={() => {
              setSelectedLoans({});
            }}
          >
            Clear All Rows
          </BaseButton>
          <BaseButton
            type="secondary"
            size="compact"
            label={`Select ${pageSize} rows`}
            className="py-[2px] px-[6px]"
            onClick={() => {
              handleSelectRowsOnPage();
            }}
          >
            Select {pageSize} Rows
          </BaseButton>
          <BaseButton
            type="primary"
            size="compact"
            label="Select For Diligence"
            className="py-[2px] px-[6px]"
            onClick={() => {
              handleSelectForDiligence();
            }}
          >
            Select For Diligence
          </BaseButton>
        </Row>
      )}
      <SelectLoansForDiligenceTable
        selection={{
          selected: selectedLoans,
          onSelectionChange: (newSelection) => {
            !is_complete && setSelectedLoans(newSelection);
          }
        }}
        data={loans}
        assetClass={assetClass}
        pagination={{
          updateParams: updateQueryParams,
          queryParams,
          totalItems: totalLoans,
        }}
      />
      <div className="w-full flex justify-between">
        <div className="flex items-center gap-2">
          <SvgIcon name="info" />
          {numSelected} Loans Selected
        </div>
      </div>
    </TabContent>
  );
};

