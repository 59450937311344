import {mapValues, map} from 'lodash';
import { Form, Formik, FormikConfig, FormikProps } from 'formik';
import { InputHTMLAttributes, useMemo } from 'react';
import * as yup from 'yup';
import { FormSingleInput } from './formSingleInput';
import styled from 'styled-components';

const InputsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-column-gap: 32px;
  grid-row-gap: 12px;
  margin-bottom: 3rem;
`;

export type FormConfigEntry = {
  label: string;
  yupSchema: yup.NumberSchema | yup.StringSchema;
  placeholder?: string;
  required?: boolean;
  tip?: string;
  unit?: string;
  type: InputHTMLAttributes<HTMLInputElement>['type'];
}

type FormProps<ValidFormValues extends object> = {
  formConfig: { [K in keyof ValidFormValues]: FormConfigEntry };
  formValues: Partial<ValidFormValues>;
  onSubmit: (formValues: ValidFormValues) => void;
};

export const FormWithSingleInputs = <ValidFormValues extends {[key: string]: string | number }>(props: FormProps<ValidFormValues>): JSX.Element => {
  type FormConfig = typeof props.formConfig;
  type FormikValues = {[K in keyof ValidFormValues]: number | string};

  const initialValues = useMemo(() => {
    return mapValues(props.formConfig, (_, key) => props.formValues[key as keyof FormConfig] || '');
  }, [props.formConfig, props.formValues]);

  const initialTouched = mapValues(initialValues, (val) => val && val !== '');

  function handleFieldBlur(e: React.FocusEvent, formikProps:FormikProps<FormikValues>) {
    formikProps.handleBlur(e);
    if(formikProps.isValid){
      formikProps.submitForm();
    }
  }

  function getFieldProps(formikProps: FormikProps<FormikValues>, fieldId: keyof FormConfig){
    return {
      ...formikProps.getFieldProps(fieldId),
      onBlur: (e:React.FocusEvent) => handleFieldBlur(e, formikProps),
      type: props.formConfig[fieldId].type
    };
  }

  return (
    <Formik<FormikValues> initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        props.onSubmit(values as ValidFormValues);
        actions.setSubmitting(false);
      }}
      validationSchema= {yup.object({
        ...(() => {
          const schema = mapValues<FormConfig, yup.AnySchema>(
            props.formConfig,
            (field) => field.yupSchema
          );
          return schema;
        })(),
      })}
      validateOnMount={true}
      validateOnChange={true}
      initialTouched={initialTouched as FormikConfig<FormikValues>['initialTouched']} //it seems there is an issue with their types
    >
      {formikProps =>
        (<Form>
          <InputsContainer>
            {map(props.formConfig, (field, key) => (
              <FormSingleInput
                fieldProps={getFieldProps(formikProps, key)}
                fieldMeta= {formikProps.getFieldMeta(key)}
                key={key}
                label={field.label}
                unit={field.unit}
                tip={field.tip}
                placeholder={field.placeholder}
              />
            ))}
          </InputsContainer>
        </Form>)
      }
    </Formik>
  );
};
