import {
  BaseTableProps,
  LoanColumnDef,
  LoanTable,
  getDefaultColumns,
} from 'features/drilldown/LoanDatatable/LoanTable';
import keyBy from 'lodash/keyBy';
import { AssetClassToLoanType } from 'app-level/config/assetClassTypes';
import AddNoteIcon from './AddNoteIcon';
import { LoanDiligenceStatus, LoanStatusDropdown } from './LoanDiligenceStatus';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { HoverContent } from 'common-ui/Table/tableStyles';
import { DealRole } from '__generated__/globalTypes';
import { useLoanDiligence } from './contexts/LoanDiligenceProvider';
import {
  GetDealDiligence_deal,
  GetDealDiligence_deal_diligence,
} from 'query/__generated__/GetDealDiligence';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDialog } from 'common-ui/Dialog';
import DiligenceNotesDialog from './DiligenceNotesDialog';
import isEqual from 'react-fast-compare';

export type LoanDiligenceStatusMap = {
  [loanId: string]: LoanDiligenceStatus;
};

interface DiligenceLoanTableProps<K extends keyof AssetClassToLoanType> {
  deal?: GetDealDiligence_deal | null;
  role: DealRole;
  assetClass: K;

  sorting: BaseTableProps<K>['sorting'];

  onViewClick: (loanId: string) => void;
  onQuickStatusClick: (loanId: string) => void;
  onLoanDiligenceStatusChanged: (
    loanId: string,
    status: LoanDiligenceStatus
  ) => void;
  pagination: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateParams: (newParams: Partial<TableQueryParameters<any, any>>) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryParams: TableQueryParameters<any, any>;
    totalItems?: number;
  };
}

const _ReviewDiligenceTable = <K extends keyof AssetClassToLoanType>({
  deal,
  role,
  assetClass,
  sorting,
  onViewClick,
  onQuickStatusClick,
  onLoanDiligenceStatusChanged,
  pagination,
}: DiligenceLoanTableProps<K>) => {
  const loanDiligence = useLoanDiligence();
  const [selectedLoanId, setSelectedLoanId] = useState<string | null>(null);
  const diligenceNoteDialog = useDialog();

  const [localStatusChanges, setLocalStatusChanges] = useState<{[loanId: string]: LoanDiligenceStatus}>({});
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const diligenceLoans = useMemo(() => {
    const loans = deal?.diligence.map((diligence) => {
      const loan = diligence.loan as AssetClassToLoanType[K];
      return {
        ...loan,
        status: localStatusChanges[loan.id] || diligence.status
      };
    }) || [];
    return loans;
  }, [deal?.diligence, localStatusChanges]);

  const loanIdToDiligenceMap = useMemo(() => {
    const baseMap = keyBy(deal?.diligence, (diligence) => diligence.loan.id);
    return Object.keys(baseMap).reduce((acc, loanId) => {
      acc[loanId] = {
        ...baseMap[loanId],
        status: localStatusChanges[loanId] || baseMap[loanId].status
      };
      return acc;
    }, {} as {[loanId: string]: GetDealDiligence_deal_diligence});
  }, [deal?.diligence, localStatusChanges]);

  const selectedDiligence = useMemo(() =>
    selectedLoanId ? loanIdToDiligenceMap[selectedLoanId] : null,
  [loanIdToDiligenceMap, selectedLoanId]);

  const handleNoteIconClick = useCallback((loanId: string) => {
    setSelectedLoanId(loanId);
    diligenceNoteDialog.setIsOpen(true);
  }, [diligenceNoteDialog]);

  const handleViewClick = useCallback((id: string) => {
    onViewClick?.(id);
  }, [onViewClick]);

  const handleQuickStatusClick = useCallback((id: string) => {
    onQuickStatusClick?.(id);
  }, [onQuickStatusClick]);

  const handleLoanDiligenceStatusChanged = useCallback((id: string, status: LoanDiligenceStatus) => {
    setLocalStatusChanges(prev => ({...prev, [id]: status}));
    setUpdateTrigger(prev => prev + 1);
    onLoanDiligenceStatusChanged(id, status);
  }, [onLoanDiligenceStatusChanged]);

  useEffect(() => {
    setLocalStatusChanges({});
  }, [deal]);

  const statusColumn: LoanColumnDef<K> = useMemo(() => ({
    header: 'Status',
    id: 'status',
    cell: (props) => {
      const diligence = loanIdToDiligenceMap[props.row.original.id];
      const status = diligence.status;
      return (
        <div className='flex items-center'>
          {role === DealRole.SELLER ? (
            <div>{status}</div>
          ) : (
            <LoanStatusDropdown
              value={status}
              onChange={(newStatus) => handleLoanDiligenceStatusChanged(props.row.original.id, newStatus)}
            />
          )}
          <HoverContent>
            <div className='flex items-center gap-6'>
              <button
                className='text-[#d743bd] underline hover:text-accent2-emphasis'
                type='button'
                onClick={() => handleViewClick(props.row.original.id)}
              >
                View
              </button>
              <button
                className='text-[#d743bd] underline hover:text-accent2-emphasis'
                type='button'
                onClick={() => handleQuickStatusClick(props.row.original.id)}
              >
                Quick Status View
              </button>
              <AddNoteIcon
                onNoteClick={() => handleNoteIconClick(props.row.original.id)}
                noteStatus={diligence.note_status}
              />
            </div>
          </HoverContent>
        </div>
      );
    },
  }), [role, loanIdToDiligenceMap, handleLoanDiligenceStatusChanged, handleViewClick, handleQuickStatusClick, handleNoteIconClick]);

  const columns = useMemo(() =>
    [statusColumn, ...getDefaultColumns(assetClass, false)],
  [statusColumn, assetClass]);

  if (!deal) {
    return null;
  }

  return (
    <div className='relative'>
      {loanDiligence.processingLoanIds.length > 0 && (
        <div className='absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10'>
          <div className='text-white text-xl'>
            Processing diligence for {loanDiligence.processingLoanIds.length} loans... please wait.
          </div>
        </div>
      )}
      <LoanTable
        key={`loan-table-${updateTrigger}`}
        data={diligenceLoans}
        strings={{ empty: 'Waiting for buyer to select loans' }}
        sorting={sorting}
        assetClass={assetClass}
        // @ts-expect-error - TS types are wrong, should be fixed in next iteration
        columns={columns}
        pagination={pagination}
        omitColumnIds={role === DealRole.BUYER ? ['listing'] : []}
      />
      {selectedDiligence && (
        <DiligenceNotesDialog
          dialog={diligenceNoteDialog}
          documentName={selectedDiligence?.loan.account_id || 'Unknown Loan'}
          diligenceId={selectedDiligence?.id}
        />
      )}
    </div>
  );
};

export const ReviewDiligenceTable = memo(_ReviewDiligenceTable, (prevProps, nextProps) => {
  return (
    prevProps.deal === nextProps.deal &&
    prevProps.role === nextProps.role &&
    prevProps.assetClass === nextProps.assetClass &&
    isEqual(prevProps.sorting, nextProps.sorting) &&
    isEqual(prevProps.pagination, nextProps.pagination)
  );
});

