import { Subheader } from 'ui-kit';
import { Tab, Tabs } from 'common-ui/Tabs';
import { Note } from './DealDocumentTypes';
import { SelectLoansProps, SelectLoansTab } from './SelectLoansTab';
import { ReviewDiligenceProps, ReviewDiligenceTab } from './ReviewDiligenceTab';
import { DiligenceCard_DiligenceCard } from './__generated__/DiligenceCard';
import DiligencePreVsPost from './DiligencePreVsPost';
import { DealRole } from '__generated__/globalTypes';
import { useState } from 'react';

export type LoanNotesMap = {
  [loanId: string]: Note[];
};
export interface DiligenceProps {
  selectLoansProps: SelectLoansProps;
  reviewDiligenceProps: ReviewDiligenceProps;
  role: DealRole;
  originalPoolSummary: DiligenceCard_DiligenceCard['original_pool_summary'];
  acceptedBidSummary: DiligenceCard_DiligenceCard['accepted_bid_summary'];
  postDiligenceSummary: DiligenceCard_DiligenceCard['post_diligence_summary'];
  diligenceReports: DiligenceCard_DiligenceCard['diligence_reports'];
}

export const Diligence = ({
  selectLoansProps,
  reviewDiligenceProps,
  role,
  originalPoolSummary,
  acceptedBidSummary,
  postDiligenceSummary,
  diligenceReports,
}: DiligenceProps) => {
  const isSeller = role === DealRole.SELLER;
  const [activeTab, setActiveTab] = useState(0);

  const switchToReviewDiligence = () => {
    setActiveTab(1);
  };

  const renderContent = () => {
    if (isSeller) {
      return <ReviewDiligenceTab {...reviewDiligenceProps} />;
    }

    return (
      <>
        <Subheader>Manage diligence</Subheader>
        <Tabs activeTab={activeTab} onTabChange={setActiveTab}>
          <Tab label="Select Loans">
            <SelectLoansTab
              {...selectLoansProps}
              switchToReviewDiligence={switchToReviewDiligence}
            />
          </Tab>
          <Tab label="Review Diligence">
            <ReviewDiligenceTab {...reviewDiligenceProps} />
          </Tab>
          <Tab label="Pre vs Post Diligence Deal Parameters">
            <DiligencePreVsPost
              originalPoolSummary={originalPoolSummary}
              acceptedBidSummary={acceptedBidSummary}
              postDiligenceSummary={postDiligenceSummary}
              diligenceReports={diligenceReports}
            />
          </Tab>
        </Tabs>
      </>
    );
  };

  return (
    <div className='text-slate-200'>
      <div className='flex flex-col gap-6'>
        {renderContent()}
      </div>
    </div>
  );
};
