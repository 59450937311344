import { DropdownPillComboboxSingleValue } from 'common-ui';
import { BaseFiltersConfig, Option, SingleSelectFilter, SingleSelectFilterConfigEntry } from '../filters.types';
import type { CommonProps } from '../PillFilters';

export function SingleSelectPillFilter<T extends BaseFiltersConfig>({
  filter,
  filterConfig,
  updateFilter,
  removeFilter,
  index,
}: CommonProps<T> & {
  filter: SingleSelectFilter<T>;
  filterConfig: SingleSelectFilterConfigEntry<Option>;
}) {
  const { displayName, ...comboProps } = filterConfig;
  const selectedOption = filterConfig.filterOptions.find(
    (option) => comboProps.getOptionValue(option) === filter.value
  );

  return (
    <DropdownPillComboboxSingleValue
      {...comboProps}
      options={filterConfig.filterOptions}
      description={displayName}
      value={selectedOption || null}
      onValueChanged={(option) =>
        option
          ? updateFilter(filter.name, index, comboProps.getOptionValue(option))
          : null
      }
      iconName="pill-close"
      iconOnClick={() => removeFilter(filter.name, index)}
    />
  );
}
