import { FilterableField } from '__generated__/globalTypes';
import { FIELD_CONFIG } from 'configs/columns';
import { invert, cloneDeep, map } from 'lodash';
import { BooleanFilterConfigEntry, RangeFilterConfigEntry, MultiSelectFilterConfigEntry, isOption } from 'features/common-elements/filters/filters.types';
import { AssetClassId } from 'models/AssetClass';
import { STATES } from 'configs/states';
import { isArrayOf } from 'functions/typeUtils';

//these were not implemented
//FilterableField.city
//FilterableField.account_id
//FilterableField.listing_id
//FilterableField.postal_code
//FilterableField.last_updated_date_seconds problematic..
//FilterableField.maturity_date_seconds
//FilterableField.next_payment_date_seconds
//FilterableField.origination_date_seconds

export const filterFieldToAbbr = {
  [FilterableField.age_months]: 'age',
  [FilterableField.auto_original_value_cents]: 'aov',
  [FilterableField.borrower_credit_score]: 'bcs',
  [FilterableField.current_balance_cents]: 'cba',
  [FilterableField.dti]: 'dti',
  [FilterableField.interest_rate]: 'ir',
  [FilterableField.loan_terms_months]: 'ltm',
  [FilterableField.ltv]: 'ltv',
  [FilterableField.original_balance_cents]: 'oba',
  [FilterableField.remaining_loan_terms_months]: 'rlt',
  [FilterableField.cra]: 'cra',
  [FilterableField.state]: 'st',
};

export const abbrToFilterField = invert(filterFieldToAbbr) as Record<string, PortfolioFilterField>;

export const portfolioHomeFiltersConfig = {
  [FilterableField.age_months]: {
    type: 'range',
    displayName: FIELD_CONFIG.age_months.string,
    min: 1,
    max: 360,
    integer: true,
  },
  [FilterableField.cra]: {
    type: 'boolean',
    displayName: FIELD_CONFIG.cra.string,
    falseyLabel: 'Without CRA',
    truthyLabel: 'With CRA',
  },
  [FilterableField.state]: {
    type: 'select-multi',
    displayName: FIELD_CONFIG.state.string,
    filterOptions: map(STATES, (_value, key) => ({ value: key, label: key })),
    getOptionLabel: (option) => typeof option === 'string' ? option : option.label,
    getOptionValue: (option) => typeof option === 'string' ? option : option.value,
    valueToString: (options) => isArrayOf(isOption)(options) ? options.map(option => option.label).join(', ') : '',
  },
  [FilterableField.borrower_credit_score]: {
    type: 'range',
    displayName: FIELD_CONFIG.borrower_credit_score.string,
    min: 300,
    max: 850,
    integer: true,
  },
  [FilterableField.current_balance_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.current_balance_cents.string,
    min: 1,
    max: 200000000,
    prepare: (value: number) => value * 100,
  },
  [FilterableField.dti]: {
    type: 'range',
    displayName: FIELD_CONFIG.dti.string,
    min: 0.2,
    max: 1.5,
    decimal: true,
  },
  [FilterableField.interest_rate]: {
    type: 'range',
    displayName: FIELD_CONFIG.interest_rate.string,
    min: 1,
    max: 9,
    percentage: true,
  },
  [FilterableField.ltv]: {
    type: 'range',
    displayName: FIELD_CONFIG.ltv.string,
    min: 0.2,
    max: 1.5,
    decimal: true,
  },
  [FilterableField.original_balance_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.original_balance_cents.string,
    min: 1,
    max: 200000000,
    prepare: (value: number) => value * 100,
  },
  [FilterableField.remaining_loan_terms_months]: {
    type: 'range',
    displayName: FIELD_CONFIG.remaining_loan_terms.string,
    min: 1,
    max: 360,
  },
} satisfies { [key in FilterableField]?: RangeFilterConfigEntry | BooleanFilterConfigEntry | MultiSelectFilterConfigEntry<{value: string, label: string}> };

const portfolioCarsFiltersConfig = {
  ...cloneDeep(portfolioHomeFiltersConfig),
  [FilterableField.auto_original_value_cents]: {
    type: 'range',
    displayName: FIELD_CONFIG.auto_original_value_cents.string,
    min: 1,
    max: 100000,
    prepare: (value: number) => value * 100,
  },
};

portfolioCarsFiltersConfig[FilterableField.ltv].max = 150;
portfolioCarsFiltersConfig[FilterableField.age_months].max = 60;
portfolioCarsFiltersConfig[FilterableField.current_balance_cents].max = 100000;
portfolioCarsFiltersConfig[FilterableField.interest_rate].max = 9;
portfolioCarsFiltersConfig[FilterableField.original_balance_cents].max = 100000;
portfolioCarsFiltersConfig[FilterableField.remaining_loan_terms_months].max = 84;

export function getPortfolioFiltersConfig(assetClass: AssetClassId) {
  switch(assetClass){
    case 'home':
    case 'cre':
      return portfolioHomeFiltersConfig;
    case 'auto':
      return portfolioCarsFiltersConfig;
    default:
      throw new Error(`No filters config for asset class ${assetClass}`);
  }
}

export type PortfolioFilterConfig = ReturnType<typeof getPortfolioFiltersConfig>;
export type PortfolioFilterField = keyof PortfolioFilterConfig;
