import React from 'react';
import {
  CarveDetailsValue,
  CarveDetailsLabel,
  CarveDetailsContainer,
  CarveDetailsRow,
  CarveName,
} from 'ui-kit';
import { CarveSummary } from 'features/deals/DealStages/EventActionCards/CarveDiffTable/__generated__/CarveSummary';
import { FIELD_CONFIG, formatPercent } from 'configs/columns';
import { IndeterminateCheckbox } from 'common-ui';
import { CarveSpecification } from 'features/deals/DealStages/EventActionCards/CarveDetailsCardBody/types';

const carveDetailsTableRows = [
  {
    label: 'O BAL',
    value: (carve: CarveSummary) => carve.original_balance_cents,
    display: FIELD_CONFIG.original_balance_cents.display,
  },
  {
    label: 'UPB',
    value: (carve: CarveSummary) => carve.current_balance_cents,
    display: FIELD_CONFIG.current_balance_cents.display,
  },
  {
    label: 'UPB/O BAL',
    value: (carve: CarveSummary) =>
      carve.current_balance_cents / carve.original_balance_cents,
    display: (value: number) => formatPercent(value, 2),
  },
  {
    label: '# LOANS',
    value: (carve: CarveSummary) => carve.loan_count,
    display: (value: number) => value.toLocaleString(),
  },
  {
    label: 'WALA',
    value: (carve: CarveSummary) => carve.wa_remaining_loan_terms_months,
    display: FIELD_CONFIG.remaining_loan_terms.display,
  },
  {
    label: 'WAM',
    value: (carve: CarveSummary) => carve.wa_age_months,
    display: FIELD_CONFIG.age_months.display,
  },
  {
    label: 'GWAC',
    value: (carve: CarveSummary) => carve.wa_coupon,
    display: FIELD_CONFIG.interest_rate.display,
  },
  {
    label: 'FICO',
    value: (carve: CarveSummary) => carve.wa_borrower_credit_score,
    display: FIELD_CONFIG.borrower_credit_score.display,
  },
  {
    label: 'LTV',
    value: (carve: CarveSummary) => carve.wa_ltv,
    display: FIELD_CONFIG.ltv.display,
  },
  {
    label: 'DTI',
    value: (carve: CarveSummary) => carve.wa_dti,
    display: FIELD_CONFIG.dti.display,
  },
  // {
  //   label: 'DQ',
  //   value: (carve: CarveSummary) => '-', // You can adjust this as needed
  //   display: (value: string) => value,
  // },
];

const CarveDetailsTable = ({
  carveSummary,
}: {
  carveSummary: CarveSummary;
}) => {
  return (
    <CarveDetailsContainer>
      {carveDetailsTableRows.map((rowConfig, index) => (
        <CarveDetailsRow key={index}>
          <CarveDetailsLabel>{rowConfig.label}</CarveDetailsLabel>
          <CarveDetailsValue>
            {rowConfig.display(rowConfig.value(carveSummary))}
          </CarveDetailsValue>
        </CarveDetailsRow>
      ))}
    </CarveDetailsContainer>
  );
};

export interface CarveCardProps {
  isSelected: boolean;
  carve: CarveSpecification;
  onCarveSelected: () => void;
}

const CarveCard: React.FC<CarveCardProps> = ({
  carve,
  isSelected,
  onCarveSelected,
}) => {
  return (
    <>
      <CarveName>
        <IndeterminateCheckbox
          checked={isSelected}
          onClick={() => onCarveSelected()}
          onChange={(event) => {
            if (event.target.checked) {
              onCarveSelected();
            }
          }}
        />
        {carve.name}
        {carve.isDraft ? ' (not saved)' : ''}
        {carve.isUpdating ? ' (updating...)' : ''}
        {carve.isDeleted ? ' (deleting...)' : ''}
      </CarveName>
      {
        carve.performanceSummary && (
          <CarveDetailsTable carveSummary={carve.performanceSummary} />
        )
      }
    </>
  );
};

export { CarveCard, CarveDetailsTable };
