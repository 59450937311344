import { gql } from '@apollo/client';

const GET_LOAN = gql`
  query GetLoanAccount($accountId: ID!, $companyId: ID!) {
    account(account_id: $accountId, company_id: $companyId) {
      id
      account_id
      age_months
      asset_class
      borrower_credit_score
      current_balance_cents
      interest_rate
      last_updated_date
      listing {
        id
        name
      }
      loan_terms_months
      maturity_date
      next_payment_date
      original_balance_cents
      first_payment_date
      remaining_loan_terms_months
      servicing_rate
      state
      version
      ... on AutoLoan {
        auto_original_value_cents
        auto_vin
        city
        dti
        ltv
        postal_code
        property_year
        property_description
      }
      ... on HomeLoan {
        city
        dti
        ltv
        occupancy
        postal_code
        property_year
        property_description
        purpose
      }
    }
  }
`;

export { GET_LOAN };
