import { BaseButton } from 'common-ui';
import { PillFilters } from 'features/common-elements';
import DiligencePopulationMetrics, {
  DiligencePopulationMetricsProps,
} from './DiligencePopulationMetrics';
import { DiligenceStatus } from './DiligenceStatus';
import { ReviewDiligenceTable } from './ReviewDiligenceTable';
import { Row, SmallRowLabel, SpaceBetween, TabContent } from './commonStyles';
import { AssetClass, DealRole } from '__generated__/globalTypes';
import { getPortfolioFiltersConfig } from 'features/pages/portfolio/Portfolio/portfolioFilters.config';
import AssetClassConfig from 'models/AssetClass';
import { useCallback, useMemo, useState } from 'react';
import { LoanDiligenceStatus } from './LoanDiligenceStatus';
import { Dialog, useDialog } from 'common-ui/Dialog';
import { QuickStatusDialog } from './QuickStatusDialog';
import { FileDiligenceStatus } from './FileStatus';
import DiligenceLoanDocumentDrawer, {
  DocumentsProps,
} from './DiligenceLoanDocumentDrawer';
import { GetDealDiligence_deal_diligence } from 'query/__generated__/GetDealDiligence';
import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import { TableQueryParameters } from 'functions/useTableQueryParams';
import { useLoansSelectedForDiligence } from './queries/useLoansSelectedForDiligence';
import { useMutation } from '@apollo/client';
import {
  MarkDiligenceComplete,
  MarkDiligenceCompleteVariables,
} from 'mutation/__generated__/MarkDiligenceComplete';
import { MARK_DILIGENCE_COMPLETE } from 'mutation/markDiligenceComplete';

export interface ReviewDiligenceProps {
  dealId: string;
  dealName: string;
  assetClass: AssetClass;
  role: DealRole;
  is_complete: boolean;
  reviewDiligenceMetrics: DiligencePopulationMetricsProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryParams: TableQueryParameters<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateQueryParams: (params: Partial<TableQueryParameters<any, any>>) => void;
  totalDiligenceItems?: number;
  diligenceData: GetDealDiligence_deal_diligence[];
  handleLoanDiligenceStatusChanged: (
    loanId: string,
    status: LoanDiligenceStatus
  ) => void;
  fetchCollateralNotes: (diligenceIds: string[]) => void;
  documents: DocumentsProps; // Collateral documents
}

export const ReviewDiligenceTab: React.FC<ReviewDiligenceProps> = ({
  dealId,
  dealName,
  reviewDiligenceMetrics,
  role,
  is_complete,
  queryParams,
  updateQueryParams,
  totalDiligenceItems,
  assetClass,
  diligenceData,
  handleLoanDiligenceStatusChanged,
  fetchCollateralNotes,
  documents,
}) => {
  const { deal, setActionTargetLoanId, selectedDiligenceForDialog } =
    useReviewDiligenceTab(dealId, assetClass);

  const markCompleteDialog = useDialog();
  const [markDiligenceComplete] = useMutation<
    MarkDiligenceComplete,
    MarkDiligenceCompleteVariables
  >(MARK_DILIGENCE_COMPLETE, {
    onCompleted: () => {
      markCompleteDialog.closeDialog();
      setIsMarkingComplete(false);
    },
  });
  const [isMarkingComplete, setIsMarkingComplete] = useState<boolean>(false);

  const handleMarkCompleteClick = () => {
    setIsMarkingComplete(true);
    const input = {
      variables: {
        input: {
          deal_id: dealId,
        },
      },
    };
    markDiligenceComplete(input);
  };

  const portfolioFiltersConfig = assetClass
    ? getPortfolioFiltersConfig(AssetClassConfig[assetClass].id)
    : null;

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [drawerLoanId, setDrawerLoanId] = useState<string>('');

  const quickStatusDialog = useDialog();

  const handleQuickStatusChange = (
    docId: string,
    status: FileDiligenceStatus
  ) => {
    documents.onFileStatusSelect(docId, status);
  };

  const handleQuickStatusClick = (loanId: string) => {
    setActionTargetLoanId(loanId);
    quickStatusDialog.openDialog();
  };

  const handleViewClick = (loanId: string) => {
    setDrawerLoanId(loanId);
    setIsDrawerOpen(true);
  };

  const handleLoanSelectedInDrawer = (loanId: string) => {
    setDrawerLoanId(loanId);
  };

  return (
    <>
      <TabContent>
        <Row>
          <SmallRowLabel>Diligence Population</SmallRowLabel>
          <DiligencePopulationMetrics metrics={reviewDiligenceMetrics} />
        </Row>
        <Row>
          <SmallRowLabel>Diligence Status</SmallRowLabel>
          <DiligenceStatus diligenceData={diligenceData} />
        </Row>
        <Row>
          <SpaceBetween>
            <div>
              {portfolioFiltersConfig && (
                <PillFilters
                  filtersConfig={portfolioFiltersConfig}
                  currentFilters={queryParams.filters}
                  setCurrentFilters={(filters) =>
                    updateQueryParams({ ...queryParams, filters })
                  }
                />
              )}
            </div>
            <div>
              {role === DealRole.BUYER && !is_complete && ( // TODO only show this if the deal is in DILIGENCE state or there's an active action
                <BaseButton
                  type="secondary"
                  size="medium"
                  label="Mark Diligence Review Complete"
                  icon="icon-complete"
                  isIconRight={true}
                  onClick={() => markCompleteDialog.openDialog()}
                >
                  Mark Diligence Review Complete
                </BaseButton>
              )}
            </div>
          </SpaceBetween>
        </Row>
        <ReviewDiligenceTable
          role={role}
          onLoanDiligenceStatusChanged={handleLoanDiligenceStatusChanged}
          deal={deal}
          assetClass={assetClass}
          sorting={{
            state: queryParams.sortings,
            onSortingChanged: (newSortings) => {
              updateQueryParams({ sortings: newSortings });
            },
          }}
          onViewClick={handleViewClick}
          onQuickStatusClick={handleQuickStatusClick}
          pagination={{
            updateParams: updateQueryParams,
            queryParams,
            totalItems: totalDiligenceItems,
          }}
        />
      </TabContent>

      <QuickStatusDialog
        loanName={
          selectedDiligenceForDialog?.loan.account_id || 'Unknown Loan'
        }
        collateral={selectedDiligenceForDialog?.collateral || []}
        dialog={quickStatusDialog}
        onStatusChange={handleQuickStatusChange}
      />
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => setIsDrawerOpen(false)}
        />
      )}
      <div
        className={`fixed top-0 right-0 w-[1070px] h-full bg-background-surface shadow-lg transform transition-transform duration-300 ease-in-out z-20 ${
          isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <DiligenceLoanDocumentDrawer
          role={role}
          dealName={dealName}
          diligenceSelector={{
            diligence: diligenceData,
            selectedLoanId: drawerLoanId,
            onSelectLoan: handleLoanSelectedInDrawer,
            fetchCollateralNotes,
          }}
          documents={documents}
        />
      </div>
      <Dialog dialog={markCompleteDialog}>
        <div className="modal__container">
          <div className="modal__header_container">
            <div className="modal__header_text">Mark Diligence Complete</div>
            <div className="modal__header_caption">
              By marking this diligence complete, you are confirming that you
              no longer need to review any additional diligence or collateral
              material to transact on this deal.
            </div>
          </div>
          <div className="modal__body">
            <div className="flex justify-between mt-5">
              <BaseButton
                type="primary"
                label="Accept"
                onClick={handleMarkCompleteClick}
                disabled={isMarkingComplete}
              >
                Accept
              </BaseButton>
              <BaseButton
                type="secondary"
                label="Decline"
                onClick={() => markCompleteDialog.closeDialog()}
              >
                Decline
              </BaseButton>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const useReviewDiligenceTab = (dealId: string, assetClass: AssetClass) => {
  const { data } = useLoansSelectedForDiligence(dealId, assetClass);

  const [actionTargetLoanId, setActionTargetLoanId] = useState<string | null>(
    null
  );

  const diligenceLoans = useMemo(
    () =>
      data?.deal?.diligence.map(
        (diligence) => diligence.loan as LoanDatatableLoan
      ) || [],
    [data?.deal?.diligence]
  );

  const selectedDiligenceForDialog = useMemo(
    () =>
      data?.deal?.diligence.find(
        (diligence) => diligence.loan.id === actionTargetLoanId
      ),
    [data?.deal?.diligence, actionTargetLoanId]
  );

  const setActionTargetLoanIdCallback = useCallback((id: string | null) => {
    setActionTargetLoanId(id);
  }, []);

  return useMemo(
    () => ({
      deal: data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanId: setActionTargetLoanIdCallback,
    }),
    [
      data?.deal,
      diligenceLoans,
      selectedDiligenceForDialog,
      actionTargetLoanId,
      setActionTargetLoanIdCallback,
    ]
  );
};

