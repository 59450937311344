export interface DocumentPreviewProps {
  documentUrl: string;
}

const DocumentPreview = ({ documentUrl }: DocumentPreviewProps) => {
  return (
    <iframe
      title="Document Preview"
      src={documentUrl}
      width="100%"
      height="780"
      style={{ border: 'none' }}
    ></iframe>
  );
};

export default DocumentPreview;
