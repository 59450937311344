import { gql } from '@apollo/client';

const RequestAccessFragments = {
  PublicListing: gql`
    fragment RequestAccessPublicListing on PublicListing {
      id
      name
      last_updated_date
      created_date
      loan_count
      pricing_type
      servicing_preference
      servicing_fee_rate
      top_states
      t_current_balance_cents
      wa_borrower_credit_score
      wa_coupon
      wa_current_balance_cents
      wa_dti
      wa_loan_age_months
      wa_remaining_loan_terms_months
      wa_ltv
      company {
        type
      }
      in_progress_deal_id
    }
  `,
};

export {
  RequestAccessFragments
};
