import  { useState, useEffect, useCallback, FC } from 'react';
import { FetchResult, QueryResult, OperationVariables } from '@apollo/client';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { Pill, SortablePill } from 'ui-kit/Pill';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import { IconEdit, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { SavePoolSettings } from 'query/__generated__/SavePoolSettings';
import { GetPoolSettings } from 'query/__generated__/GetPoolSettings';
import { KPI } from '../Dashboard/PoolManager/types';

type MetricsHeaderProps = {
  title: string;
  numPools?: number;
  metrics: KPI[];
  addUserSetting: (value: string) => Promise<FetchResult<SavePoolSettings>>;
  savedPoolSettings: QueryResult<GetPoolSettings, OperationVariables>;
  userPoolSettings?: string[];
};

const Tooltip: FC<{label: string}> = ({ children, label }) => {
  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root>
        <RadixTooltip.Trigger asChild>
          {children}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className='bg-grey-950 text-grey-400 p-2 rounded-md shadow-2xl' sideOffset={5} >
            {label}
            <RadixTooltip.Arrow className='fill-grey-950' />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

export const MetricsHeader: FC<MetricsHeaderProps> = ({
  title,
  numPools,
  metrics,
  addUserSetting,
  savedPoolSettings,
  userPoolSettings,
}) => {
  const { loading, error } = savedPoolSettings;
  const [isEditMode, setIsEditMode] = useState(false);
  const [displayedMetrics, setDisplayedMetrics] = useState<KPI[]>([]);
  const [editDisplayedMetrics, setEditDisplayedMetrics] = useState<KPI[]>([]);
  const [menuMetrics, setMenuMetrics] = useState<KPI[]>([]);
  const [editMenuMetrics, setEditMenuMetrics] = useState<KPI[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const saveUserSettings = useCallback((metricsToUpdate: KPI[]) => {
    const selectorsToSave = metricsToUpdate.map(metric => metric.selector);
    const formattedMetrics = JSON.stringify(selectorsToSave);
    return addUserSetting(formattedMetrics);
  }, [addUserSetting]);

  useEffect(() => {
    if (!loading && !error) {
      if (!userPoolSettings || userPoolSettings.length === 0) {
        // If no metrics are saved, automatically add and save the first three
        const initialMetrics = metrics.slice(0, 3);
        setDisplayedMetrics(initialMetrics);
        setMenuMetrics(metrics.slice(3));
        saveUserSettings(initialMetrics);
      } else {
        const accumulatedMetrics = userPoolSettings
          .map(selector => metrics.find(metric => metric.selector === selector))
          .filter(Boolean) as KPI[];
        setDisplayedMetrics(accumulatedMetrics);
        setMenuMetrics(metrics.filter(metric => !accumulatedMetrics.some(m => m.selector === metric.selector)));
      }
    } else if (userPoolSettings == null) {
      setDisplayedMetrics([]);
      setMenuMetrics(metrics);
    }
  }, [userPoolSettings, metrics, loading, error, saveUserSettings]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setEditDisplayedMetrics((items) => {
        const oldIndex = items.findIndex(item => item.selector === active.id);
        const newIndex = items.findIndex(item => item.selector === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleRemove = useCallback((selector?: string) => {
    if (selector && editDisplayedMetrics.length > 1) {
      setEditDisplayedMetrics(items => {
        const removedItem = items.find(item => item.selector === selector);
        if (removedItem) {
          setEditMenuMetrics(menuItems => [...menuItems, removedItem]);
        }
        return items.filter(item => item.selector !== selector);
      });
    }
  }, [editDisplayedMetrics]);

  const handleAdd = useCallback((selector?: string) => {
    if (selector) {
      const itemToAdd = editMenuMetrics.find(item => item.selector === selector);
      if (itemToAdd) {
        setEditDisplayedMetrics(items => [...items, itemToAdd]);
        setEditMenuMetrics(items => items.filter(item => item.selector !== selector));
      }
    }
  }, [editMenuMetrics]);

  const handleEditMode = () => {
    setIsEditMode(true);
    setEditDisplayedMetrics([...displayedMetrics]);
    setEditMenuMetrics([...menuMetrics]);
  };

  const handleSave = () => {
    saveUserSettings(editDisplayedMetrics).then(() => {
      setDisplayedMetrics([...editDisplayedMetrics]);
      setMenuMetrics([...editMenuMetrics]);
      setIsEditMode(false);
    });
  };

  const handleCancel = () => {
    setEditDisplayedMetrics([...displayedMetrics]);
    setEditMenuMetrics([...menuMetrics]);
    setIsEditMode(false);
  };


  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="flex items-center mb-3">
      <div className="flex flex-row items-center justify-between">
        <div>
          <span className="max-w-52 text-slate-400 text-xs pr-8">{title}</span>
          {numPools && <span className="pr-8 text-white text-xs">{`${numPools} Pools`}</span>}
        </div>

      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis]}
      >
        <div className="flex flex-row items-center space-x-4 flex-wrap">
          {loading ? (
            <div className="flex flex-row gap-5">
              {Array.from({ length: 4 }).map((_, index) => (
                <Pill isLoading key={index} />
              ))}
            </div>
          ) : (
            <SortableContext
              items={(isEditMode ? editDisplayedMetrics : displayedMetrics).map(metric => metric.selector)}
              strategy={horizontalListSortingStrategy}
            >
              {(isEditMode ? editDisplayedMetrics : displayedMetrics).map((metric, _index) => (
                isEditMode ? (
                  <SortablePill
                    key={metric.selector}
                    id={metric.selector}
                    label={metric.description}
                    value={metric.value}
                    isDraggable={editDisplayedMetrics.length > 1}
                    isRemovable={editDisplayedMetrics.length > 1}
                    onRemove={() => handleRemove(metric.selector)}
                  />
                ) : (
                  <Pill
                    key={metric.selector}
                    label={metric.description}
                    value={metric.value}
                  />
                )
              ))}
            </SortableContext>
          )}
        </div>
      </DndContext>
      {isEditMode && (
        <RadixDropdownMenu.Root>
          <Tooltip label="Add more metrics">
            <RadixDropdownMenu.Trigger asChild>
              <button
                className="p-2 bg-grey-800 rounded-full hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-pink-400 ml-4"
                aria-label="Add more metrics">
                <IconEdit size={16} className="text-grey-400 cursor-pointer" />
              </button>
            </RadixDropdownMenu.Trigger>
          </Tooltip>
          <RadixDropdownMenu.Portal>
            <RadixDropdownMenu.Content
              className="shadow-combined px-4 py-3 bg-grey-950 rounded-md"
              sideOffset={5}
              style={{
                maxHeight: '200px',
                maxWidth: '250px',
                minWidth: '200px',
                overflowY: 'auto'
              }}
            >
              {editMenuMetrics.length > 0 ? (
                <div className="flex flex-col gap-2">
                  {editMenuMetrics.map((metric) => (
                    <Pill
                      key={metric.selector}
                      label={metric.description}
                      value={metric.value}
                      isAddableToList
                      onAdd={() => handleAdd(metric.selector)}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-grey-400 text-sm py-2 text-center">
                  <p className="mb-1">No more metrics available</p>
                </div>
              )}
              {editMenuMetrics.length > 0 && (
                <div className="mt-2 pt-2 border-t border-grey-800 text-grey-400 text-xs text-center">
                  Click '+' to add an item to metrics list
                </div>
              )}
              <RadixDropdownMenu.Arrow className="fill-grey-950" />
            </RadixDropdownMenu.Content>
          </RadixDropdownMenu.Portal>
        </RadixDropdownMenu.Root>
      )}
      <div className="flex gap-2 ml-8">
        {!isEditMode && !loading && (
          <Tooltip label="Edit metrics">
            <button
              onClick={handleEditMode}
              className="p-2 bg-grey-800 rounded-full hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
              aria-label="Edit metrics"
            >
              <IconEdit size={16} className="text-grey-200" />
            </button>
          </Tooltip>
        )}
        {isEditMode && (
          <>
            <Tooltip label="Save changes">
              <button
                onClick={handleSave}
                className="p-2 bg-grey-800 rounded-full hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
                aria-label="Save changes"
              >
                <IconDeviceFloppy size={16} className="text-grey-200" />
              </button>
            </Tooltip>
            <Tooltip label="Cancel editing">
              <button
                onClick={handleCancel}
                className="p-2 bg-grey-800 rounded-full hover:bg-grey-700 focus:outline-none focus:ring-2 focus:ring-pink-400"
                aria-label="Cancel editing"
              >
                <IconX size={16} className="text-grey-200" />
              </button>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

