import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'context/AuthContext';
import Avatar from 'common-ui/Avatar/Avatar';
import './User.scss';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_USER_INFO } from 'mutation/userData';
import { BaseButton } from 'common-ui/Buttons';
import { GET_USER_PERMISSIONS } from 'query/permissionsQueries';
import toast from 'react-hot-toast';
import { GetUserPermissions, GetUserPermissionsVariables } from 'query/__generated__/GetUserPermissions';
import { GET_USER_COMPANY } from 'query/company';
import { UserManagementUpdateUserInfo, UserManagementUpdateUserInfoVariables } from 'mutation/__generated__/UserManagementUpdateUserInfo';
import { Option } from 'features/common-elements';

export interface Address {
  address1: string;
  suite: string;
  city: string;
  state: string;
  zipcode: string;
}

// TODO: Address and Title will be added later
export interface UserModel {
  givenName: string;
  familyName: string;
  email: string;
  phone: string;
  title?: string;
  company?: string;
  address?: Address;
  roles: string;
}

const roleOptions: Option[] = [
  { value: 'Analyst', label: 'Analyst' },
  { value: 'CFO', label: 'CFO' },
  { value: 'CLO', label: 'CLO' },
  { value: 'Trader', label: 'Trader' },
  { value: 'Treasurer', label: 'Treasurer' },
  { value: 'Other', label: 'Other' },
];

const User = () => {
  const { state: authState } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState<UserModel>({
    givenName: '',
    familyName: '',
    email: '',
    phone: '',
    title: '',
    company: authState.company?.name || '',
    address: {
      address1: '',
      suite: '',
      city: '',
      state: '',
      zipcode: '',
    },
    roles: 'Other',
  });
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    if (authState.user) {
      authState.user.getUserAttributes((err, data) => {
        if (err) {
          if (process.env.NODE_ENV === 'development') {
            console.error(err); // eslint-disable-line no-console
          }
        }
        if (data) {
          const givenName = data
            .find((user) => user.getName() === 'given_name')
            ?.getValue();
          const familyName = data
            .find((user) => user.getName() === 'family_name')
            ?.getValue();
          const email = data
            .find((user) => user.getName() === 'email')
            ?.getValue();
          setUserInfo({
            givenName: givenName || '',
            familyName: familyName || '',
            email: email || '',
            phone: '',
            title: '',
            company: authState.company?.name || '',
            address: {
              address1: '',
              suite: '',
              city: '',
              state: '',
              zipcode: ''
            },
            roles: ''
          });
        }
      });
    }
  }, [authState.user, authState.company?.name]);

  useQuery<GetUserPermissions, GetUserPermissionsVariables>(GET_USER_PERMISSIONS, {
    variables: { requestData: { userEmail: userInfo.email } },
    onCompleted: (data) => {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        userID: data.userManagementGetUser.user.userID,
        givenName: data.userManagementGetUser.user.givenName || '',
        familyName: data.userManagementGetUser.user.familyName || '',
        email: data.userManagementGetUser.user.email || '',
        phone: data.userManagementGetUser.user.phoneNumber || '',
        roles: data.userManagementGetUser.permissions?.[0]?.roles?.join(', ') || '',
        lastLoginDate: data.userManagementGetUser.user.lastLoginDate || '',
      }));
    }
  });

  const [updateUserInfo] = useMutation<UserManagementUpdateUserInfo, UserManagementUpdateUserInfoVariables>(UPDATE_USER_INFO, {
    refetchQueries: [
      {
        query: GET_USER_COMPANY,
      },
      {
        query: GET_USER_PERMISSIONS,
        variables: { requestData: { userEmail: userInfo.email } },
      },
    ],
    onCompleted: () => {
      setIsEditing(false);
      toast.success('User information updated successfully!');
    },
    onError: (_error) => {
      toast.error('Failed to update user information');
    }
  });

  useEffect(() => {
    if (authState.user) {
      authState.user.getUserAttributes((err, data) => {
        if (err) {
          if (process.env.NODE_ENV === 'development') {
            console.error(err);
          }
        }
        if (data) {
          const userAttributes = data.reduce((acc, attribute) => {
            acc[attribute.Name] = attribute.Value;
            return acc;
          }, {} as Record<string, string>);

          setUserInfo((prevInfo) => ({
            ...prevInfo,
            phone: userAttributes.phone_number || '',
            email: userAttributes.email || '',
          }));
        }
      });
    }

    if (authState.company) {
      setUserInfo((prevInfo) => ({
        ...prevInfo,
        company: authState.company?.name || '',
      }));
    }
  }, [authState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    // if (name.startsWith('address.')) {
    // const addressField = name.split('.')[1] as keyof Address;
    // setUserInfo((prevInfo) => ({
    //   ...prevInfo,
    //   address: {
    //     ...prevInfo.address,
    //     [addressField]: value,
    //   },
    // }));
    // } else {
    setUserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
    // }
  };

  const handleSave = async () => {
    // setError('');

    updateUserInfo({
      variables: {
        requestData: {
          // address: JSON.stringify(userInfo.address),
          userEmail: userInfo.email,
          givenName: userInfo.givenName,
          familyName: userInfo.familyName,
          // title: userInfo.title,
        }
      },
    });
  };

  const getFieldValue = (name: string): string => {
    return userInfo[name as keyof UserModel] as string || '';
  };

  const renderInputField = (
    label: string,
    name: keyof UserModel | string,
    type = 'text',
    disabled = false,
  ) => (
    <div className="mb-[16px] w-full">
      <label className='block font-bold mb-1 text-xs capitalize text-left'>
        {label}
      </label>
      {type === 'select' ? (
        <select
          name={name}
          value={getFieldValue(name)}
          onChange={handleInputChange}
          className={`w-full border-[1px] border-[#616161] px-[12px] py-[2px] text-xs rounded-[4px] color-[#E0E0E0] radius-[4px] bg-[transparent]
            ${disabled ? 'text-[#a0a0a0] border-[#d0d0d0] cursor-not-allowed pointer-events-none' : ''}`}
          disabled={disabled}
        >
          {roleOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          type={type}
          name={name}
          value={getFieldValue(name)}
          onChange={handleInputChange}
          className={`w-full border-[1px] border-[#E0E0E0] px-[15px] py-[5px] text-sm rounded-[4px] color-[#E0E0E0] radius-[4px] bg-[transparent]
            ${disabled ? 'text-[#a0a0a0] border-[#d0d0d0] cursor-not-allowed pointer-events-none' : ''}`}
          disabled={disabled}
        />
      )}
    </div>
  );

  const renderInfoRow = (label: string, value: string | undefined) => (
    <div className='flex items-left mb-4 mt-0'>
      <div className='flex flex-row items-center'>
        <span className='block text-xs font-bold text-slate-200 uppercase w-[100px] text-left'>{label}</span>
        <span className='block text-sm text-white mw-[220px]'>{value}</span>
      </div>
    </div>
  );

  return (
    <div className='p-4 bg-gray-800 text-white rounded-md flex items-start gap-4'>
      <div className='flex items-center mb-4'>
        <Avatar name={userInfo.givenName} width='88px' height='88px' fontSize='32px' />
      </div>
      {isEditing ? (
        <form>
          <div className='flex gap-5'>
            <div className="flex w-[70%] gap-3">
              {renderInputField('First Name', 'givenName', 'text')}
              {renderInputField('Last Name', 'familyName', 'text')}
            </div>
            {/* <div>
              {renderInputField('Role', 'role', 'select')}
            </div> */}
          </div>
          {/* <div className='w-[70%]'>
            {renderInputField('Title', 'title')}
            </div> */}
          <div className='w-[70%]'>
            {renderInputField('Company', 'company', 'text', true)}
          </div>
          <div className='w-[70%]'>
            {renderInputField('Email', 'email', 'email', true)}
          </div>
          <div className='w-[70%]'>
            {renderInputField('Phone', 'phone', 'text', true)}
          </div>
          {/* <div className='flex w-[70%] gap-3'>
            {renderInputField('Address1', 'address.address1')}
            {renderInputField('Suite/Office', 'address.suite')}
          </div>
          <div className='flex w-[70%] gap-3'>
            {renderInputField('City', 'address.city')}
            {renderInputField('State', 'address.state')}
          </div>
          <div className='w-[70%]'>
            {renderInputField('Zipcode', 'address.zipcode')}
          </div> */}
          <div className='flex justify-start gap-4'>
            <BaseButton
              label='Save Info'
              type='primary'
              onClick={handleSave}
              className='mt-4'
            >
              Save info
            </BaseButton>
            <BaseButton
              label='Cancel'
              type='secondary'
              onClick={() => setIsEditing(false)}
              className='mt-4'
            >
              Cancel
            </BaseButton>
          </div>
        </form>
      ) : (
        <div className='space-y-4 flex flex-col'>
          <div className='flex items-left mb-4 mt-0 items-center'>
            <div className='flex flex-row items-center'>
              <span className='block text-xs font-bold text-slate-200 uppercase w-[100px] text-left'>Name</span>
              <span className='block text-xl font-bold text-white mw-[220px]'>{`${userInfo.givenName} ${userInfo.familyName}`.trim()}</span>
            </div>
            <div className='flex flex-row items-center ml-[25px]'>
              <span className='block text-xs font-bold text-slate-200 uppercase'>Roles</span>
              <span className='block px-[4px] py-[3px] ml-[10px] bg-[#D8DDFD] text-info-default rounded-[3px] text-sm'>{userInfo.roles}</span>
            </div>
          </div>
          {/* {renderInfoRow('Title', userInfo.title)} */}
          {renderInfoRow('Company', userInfo.company)}
          {renderInfoRow('Email', userInfo.email)}
          {renderInfoRow('Phone', userInfo.phone)}
          {/* <div className='flex items-left mb-4 mt-0'>
            <div className='flex flex-row items-center'>
              <p className='flex h-full text-sm font-bold text-slate-200 uppercase w-[100px] text-left items-start'>Address</p>
              <div className='flex flex-col text-sm text-white text-left'>
                {
                  userInfo.address && (
                    <>
                      <span>{`${userInfo.address.address1 ? userInfo.address.address1 + ', ' : ''} ${userInfo.address.suite}`}</span>
                      <span>{`${userInfo.address.city ? userInfo.address.city + ', ' : ''} ${userInfo.address.state}`}</span>
                      <span>{userInfo.address.zipcode}</span>
                    </>
                  )
                }
              </div>
            </div>
          </div> */}
          <div className='flex justify-start'>
            <BaseButton
              label='Edit'
              type='primary'
              onClick={() => setIsEditing(true)}
              className='mt-4'
            >
              Edit
            </BaseButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default User;
