import { LoanDatatableLoan } from 'features/drilldown/LoanDatatable/__generated__/LoanDatatableLoan';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  SetStateAction,
  useReducer,
} from 'react';
import {
  AssetClass,
  Filter,
  FilterableField,
  FilterOperator,
  ListingField,
  Pagination,
  Sort,
  SortableField,
  SortDirection,
} from '__generated__/globalTypes';
import { FIELD_CONFIG } from '../configs/columns';

function transformOperand(
  name: FilterableField | ListingField,
  value: string | null | undefined
): string | null {
  if (!value) {
    return null;
  }
  const field = Object.values(FIELD_CONFIG).find(
    (f) => f.filterSelector === name
  );
  if (field && field.inputFormat && field.inputFormat.inputToSearchValue) {
    return `${field.inputFormat.inputToSearchValue(value)}`;
  }
  return value;
}

function createQueryFilters(
  assetClass: AssetClass | null,
  filters: Filter[]
): Filter[] {
  return [
    {
      field_name: FilterableField.asset_class,
      operator: FilterOperator.IS,
      operand: `${assetClass}`,
    },
    ...filters.map((filter: Filter) => ({
      field_name: filter.field_name,
      operator: filter.operator,
      operand: transformOperand(filter.field_name, filter.operand),
      operandList: filter.operandList ?? null,
    })),
  ];
}

function isCursorNotEqual(
  currentCursor: DataContextPaginationCursor,
  newCursor: DataContextPaginationCursor
): boolean {
  return (
    currentCursor == null ||
    currentCursor.loanId !== newCursor.loanId ||
    currentCursor.cursorLoanSortedValue !== newCursor.cursorLoanSortedValue
  );
}

function createPaginationParam(
  _paginationCursors: Array<DataContextPaginationCursor>,
  pagination: DataContextPagination
): Pagination {
  return {
    page_size: pagination.pageSize,
    // cursor_id: paginationCursor.loanId,
    // cursor_sorted_value: paginationCursor.cursorLoanSortedValue,
    offset: pagination.pageSize * (pagination.currentPageNumber - 1),
  };
}

function setNewPaginationCursors(
  state: DataContextState,
  paginationCursors: Array<DataContextPaginationCursor>,
  setPaginationCursors: Dispatch<SetStateAction<DataContextPaginationCursor[]>>,
  loans?: LoanDatatableLoan[] | null
): void {
  if (loans) {
    const lastLoan = loans[loans.length - 1];
    const sortField =
      lastLoan && state.sort.sort_field && state.sort.sort_field in lastLoan
        ? (state.sort.sort_field as keyof typeof lastLoan) // we always expect the sort field to be in the loan
        : null;
    if (sortField != null) {
      const newCursor = {
        loanId: lastLoan.id,
        cursorLoanSortedValue: String(lastLoan[sortField]),
      };
      const currentCursor =
        paginationCursors[state.pagination.currentPageNumber];
      if (!currentCursor) {
        paginationCursors[state.pagination.currentPageNumber] = newCursor;
      } else if (isCursorNotEqual(currentCursor, newCursor)) {
        const newPaginationCursors = paginationCursors.slice(
          0,
          state.pagination.currentPageNumber
        ); // we remove the following pages in case the user went back
        newPaginationCursors.push(newCursor);
        setPaginationCursors(newPaginationCursors);
      }
    }
  }
}

export type DataContextPaginationCursor = {
  loanId: string | null;
  cursorLoanSortedValue: string | null;
};

const PAGINATION_CURSOR_INIT = [{ loanId: null, cursorLoanSortedValue: null }];

export type DataContextPagination = {
  currentPageNumber: number;
  pageSize: number;
};

export type DataContextState = {
  assetClass: AssetClass | null;
  dummy: boolean;
  filters: Array<DataContextFilter>;
  pagination: DataContextPagination;
  sort: Sort;
};

const init: DataContextState = {
  assetClass: AssetClass.AUTO,
  dummy: false,
  filters: [],
  pagination: {
    currentPageNumber: 1,
    pageSize: 25,
  },
  sort: {
    sort_field: SortableField.account_id,
    sort_direction: SortDirection.asc,
  },
};

type DataContextType = {
  state: DataContextState;
  dispatch: Dispatch<DataContextActionTypes>;
};

const DataContext = createContext<DataContextType>({
  state: init,
  dispatch: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

export interface DataContextFilter extends Filter {
  id: string;
}

enum DataContextActionType {
  TRIGGER_RELOAD = 'TRIGGER_RELOAD',
  SET_ASSET_CLASS = 'SET_ASSET_CLASS',
  REMOVE_ASSET_CLASS = 'REMOVE_ASSET_CLASS',
  ADD_FILTER = 'ADD_FILTER',
  SET_FILTERS = 'SET_FILTERS',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_FILTERS = 'REMOVE_FILTERS',
  REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS',
  SET_LOANS_SORT = 'SET_LOANS_SORT',
  SET_LOANS_CURRENT_PAGE = 'SET_LOANS_CURRENT_PAGE',
  RESET_PAGINATION = 'RESET_PAGINATION',
  SET_LOAN_PAGE_SIZE = 'SET_LOAN_PAGE_SIZE',
  INIT = 'INIT',
}

export interface ReloadAction {
  type: typeof DataContextActionType.TRIGGER_RELOAD;
}

export interface SetAssetClassAction {
  type: typeof DataContextActionType.SET_ASSET_CLASS;
  payload: AssetClass | null;
}

export interface RemoveAssetClassAction {
  type: typeof DataContextActionType.REMOVE_ASSET_CLASS;
}

export interface AddFilterAction {
  type: typeof DataContextActionType.ADD_FILTER;
  payload: DataContextFilter;
}

export interface SetFiltersAction {
  type: typeof DataContextActionType.SET_FILTERS;
  payload: Array<DataContextFilter>;
}

export interface RemoveFilterAction {
  type: typeof DataContextActionType.REMOVE_FILTER;
  payload: string;
}

export interface RemoveFiltersAction {
  type: typeof DataContextActionType.REMOVE_FILTERS;
  payload: Array<string>;
}

export interface RemoveAllFiltersAction {
  type: typeof DataContextActionType.REMOVE_ALL_FILTERS;
}

export interface SetLoansSortAction {
  type: typeof DataContextActionType.SET_LOANS_SORT;
  payload: Sort;
}

export interface SetLoansCurrentPageAction {
  type: typeof DataContextActionType.SET_LOANS_CURRENT_PAGE;
  payload: number;
}

export interface ResetPaginationAction {
  type: typeof DataContextActionType.RESET_PAGINATION;
}

export interface SetLoanPageSizeAction {
  type: typeof DataContextActionType.SET_LOAN_PAGE_SIZE;
  payload: number;
}

export interface InitAction {
  type: typeof DataContextActionType.INIT;
}

export type DataContextActionTypes =
  | ReloadAction
  | SetAssetClassAction
  | RemoveAssetClassAction
  | AddFilterAction
  | SetFiltersAction
  | RemoveFilterAction
  | RemoveFiltersAction
  | RemoveAllFiltersAction
  | SetLoansSortAction
  | SetLoansCurrentPageAction
  | ResetPaginationAction
  | SetLoanPageSizeAction
  | InitAction;

function reducer(state: DataContextState, action: DataContextActionTypes) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(action);
  }
  switch (action.type) {
    case DataContextActionType.TRIGGER_RELOAD:
      return { ...state, dummy: !state.dummy };
    case DataContextActionType.SET_ASSET_CLASS:
      return { ...state, assetClass: action.payload };
    case DataContextActionType.REMOVE_ASSET_CLASS:
      return { ...state, assetClass: null };
    case DataContextActionType.ADD_FILTER: {
      const stateFilters = state.filters;
      stateFilters.push(action.payload);
      return {
        ...state,
        filters: stateFilters,
      };
    }
    case DataContextActionType.SET_FILTERS: {
      return { ...state, filters: action.payload };
    }
    case DataContextActionType.REMOVE_FILTER:
      return {
        ...state,
        filters: state.filters.filter((filter) => filter.id !== action.payload),
      };
    case DataContextActionType.REMOVE_FILTERS:
      return {
        ...state,
        filters: state.filters.filter(
          (filter) => !action.payload.includes(filter.id)
        ),
      };
    case DataContextActionType.REMOVE_ALL_FILTERS:
      return {
        ...state,
        filters: [],
      };
    case DataContextActionType.SET_LOANS_SORT:
      return {
        ...state,
        sort: action.payload,
        pagination: {
          ...state.pagination,
          currentPageNumber: 1,
        },
      };
    case DataContextActionType.SET_LOANS_CURRENT_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPageNumber: action.payload,
        },
      };
    case DataContextActionType.RESET_PAGINATION:
      return {
        ...state,
        pagination: init.pagination,
      };
    case DataContextActionType.SET_LOAN_PAGE_SIZE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          pageSize: action.payload,
          currentPageNumber: 1,
        },
      };
    case DataContextActionType.INIT:
      return { ...init, filters: [] };
    default:
      return { ...state };
  }
}

const DataContextProvider = (
  props: PropsWithChildren<Record<never, never>>
): JSX.Element => {
  const { children } = props;
  const [state, dispatch] = useReducer<
    Reducer<DataContextState, DataContextActionTypes>
  >(reducer, init);

  return (
    <DataContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export {
  createQueryFilters,
  DataContext,
  DataContextProvider,
  DataContextActionType,
  transformOperand,
  createPaginationParam,
  setNewPaginationCursors,
  isCursorNotEqual,
  PAGINATION_CURSOR_INIT,
};
