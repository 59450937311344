import React from 'react';

type DownloadLinkProps = {
  url?: string | null;
  onClick?: () => void;
  icon: JSX.Element;
  text: string;
  disabled?: boolean;
  hoverClassName?: string;
  iconPosition?: 'before' | 'after';
  className?: string;  // Custom class for the button/link
  contentClassName?: string;  // Custom class for the content (text + icon)
};

const DownloadLink: React.FC<DownloadLinkProps> = ({
  url,
  onClick,
  icon,
  text,
  disabled = false,
  hoverClassName = 'text-accent-muted',
  iconPosition = 'after',
  className = '', // Default empty class for custom styles
  contentClassName = '', // Default empty class for content
}) => {
  const content = (
    <span className={`flex items-center ${contentClassName}`}>
      {iconPosition === 'before' && <span className="pr-2">{icon}</span>}
      <span>{text}</span>
      {iconPosition === 'after' && <span className="pl-2">{icon}</span>}
    </span>
  );

  return url ? (
    <a
      href={url}
      download
      target="_blank"
      rel="noreferrer"
      className={`flex items-center cursor-pointer text-accent-default transition-colors duration-150 ${className} ${
        disabled ? 'opacity-30 cursor-not-allowed text-slate-400' : `hover:${hoverClassName}`
      }`}
      onClick={disabled ? undefined : onClick}
    >
      {content}
    </a>
  ) : (
    <button
      type="button"
      className={`flex items-center cursor-pointer text-accent-default transition-colors duration-150 ${className} ${
        disabled ? 'opacity-30 cursor-not-allowed text-slate-400' : `hover:${hoverClassName}`
      }`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default DownloadLink;
