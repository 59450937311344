import React from 'react';

type ProgressBarProps = {
  label: string;
  rank?: number;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ label, rank }) => {
  let render = <p className='text-sm mr-2 uppercase'>No Data</p>;

  if (rank) {
    const textPositionClass = rank > 70 ? 'left-[-35px] pr-2' : 'left-0 pl-2';
    render = (
      <div className="relative w-[200px] h-[18px] rounded-sm bg-custom-gradient">
        <div className='relative h-full top-0' style={{ left: `${rank}%` }}>
          <div className="absolute left-0 top-0 h-full w-1 bg-white"></div>
          <div className={`absolute h-full flex items-center text-white text-sm font-semibold ${textPositionClass}`}>{rank} %</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between mb-4">
      <p className='text-slate-400 text-sm'>{label}</p>
      <div className='flex items-center'>
        {render}
      </div>
    </div>
  );
};

export default ProgressBar;
