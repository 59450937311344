import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useMutation, useApolloClient, gql } from '@apollo/client';
import { SELECT_LOANS_FOR_DILIGENCE } from 'mutation/selectLoansForDiligence';
import {
  SelectLoansForDiligence,
  SelectLoansForDiligenceVariables,
} from 'mutation/__generated__/SelectLoansForDiligence';
import {
  GetJobStatus,
  GetJobStatusVariables,
} from './__generated__/GetJobStatus';
import { DiligenceBatchStatus } from '__generated__/globalTypes';

const RETRY_LIMIT = 5;

const GET_JOB_STATUS = gql`
  query GetJobStatus($input: GetSelectDiligenceStatusInput!) {
    getSelectDiligenceStatus(input: $input)
  }
`;

type Job = {
  id: string;
  loanIds: string[];
  retryCount: number;
};

type State = {
  jobs: Job[];
  error: Error | null;
};

type Action =
  | { type: 'ADD_JOB'; job: Job }
  | { type: 'REMOVE_JOB'; jobId: string }
  | { type: 'INCREMENT_RETRY'; jobId: string }
  | { type: 'SET_ERROR'; error: Error };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_JOB':
      return { ...state, jobs: [...state.jobs, action.job] };
    case 'REMOVE_JOB':
      return {
        ...state,
        jobs: state.jobs.filter((job) => job.id !== action.jobId),
      };
    case 'INCREMENT_RETRY':
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job.id === action.jobId
            ? { ...job, retryCount: job.retryCount + 1 }
            : job
        ),
      };
    case 'SET_ERROR':
      return { ...state, error: action.error };
    default:
      return state;
  }
}

type LoanDiligenceContextType = {
  selectLoans: (loanIds: string[]) => void;
  processingLoanIds: string[];
  error: Error | null;
};

export const LoanDiligenceContext = createContext<LoanDiligenceContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectLoans: () => {},
  processingLoanIds: [],
  error: null,
});

type LoanDiligenceProviderProps = {
  dealId: string;
  onJobCompleted: (jobId: string) => void;
  children: React.ReactNode;
};

export const LoanDiligenceProvider: React.FC<LoanDiligenceProviderProps> = ({
  dealId,
  onJobCompleted,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, { jobs: [], error: null });
  const client = useApolloClient();

  const [selectLoansForDiligence] = useMutation<
    SelectLoansForDiligence,
    SelectLoansForDiligenceVariables
  >(SELECT_LOANS_FOR_DILIGENCE);

  const selectLoans = async (loanIds: string[]) => {
    try {
      const { data, errors } = await selectLoansForDiligence({
        variables: {
          input: {
            deal_id: dealId,
            loan_ids: loanIds,
          },
        },
      });

      if (errors) {
        // Handle GraphQL errors
        console.error(errors);
        dispatch({ type: 'SET_ERROR', error: new Error(errors[0].message) });
        return;
      }

      const jobId = data?.selectLoansForDiligence;
      if (jobId) {
        dispatch({
          type: 'ADD_JOB',
          job: { id: jobId, loanIds, retryCount: 0 },
        });
      }
    } catch (error) {
      // Handle network errors
      console.error(error);
      if (error instanceof Error) {
        dispatch({ type: 'SET_ERROR', error });
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      state.jobs.forEach(async (job) => {
        // Stop polling if we've reached the retry limit
        if (job.retryCount >= RETRY_LIMIT) {
          dispatch({ type: 'REMOVE_JOB', jobId: job.id });
          return;
        }

        try {
          const { data, errors } = await client.query<
            GetJobStatus,
            GetJobStatusVariables
          >({
            query: GET_JOB_STATUS,
            variables: { input: { id: job.id, deal_id: dealId } },
          });

          if (errors) {
            // Handle GraphQL errors
            console.error(errors);
            return;
          }

          const status = data?.getSelectDiligenceStatus;

          switch (status) {
            case DiligenceBatchStatus.NOT_STARTED:
            case DiligenceBatchStatus.IN_PROGRESS:
              dispatch({ type: 'INCREMENT_RETRY', jobId: job.id });
              break;
            case DiligenceBatchStatus.COMPLETED:
              dispatch({ type: 'REMOVE_JOB', jobId: job.id });
              onJobCompleted(job.id);
              break;
          }
        } catch (error) {
          // Handle network errors
          console.error(error);
          if (error instanceof Error) {
            dispatch({ type: 'SET_ERROR', error });
          }
          dispatch({ type: 'INCREMENT_RETRY', jobId: job.id });
        }
      });
    }, 1000); // Poll every second

    return () => clearInterval(interval);
  }, [client, dealId, state.jobs, onJobCompleted]);

  return (
    <LoanDiligenceContext.Provider
      value={{
        selectLoans,
        processingLoanIds: state.jobs.flatMap((job) => job.loanIds),
        error: state.error,
      }}
    >
      {children}
    </LoanDiligenceContext.Provider>
  );
};

export const useLoanDiligence = () => useContext(LoanDiligenceContext);
