import { gql, useMutation } from '@apollo/client';
import { BaseButton } from 'common-ui';
import { CREATE_DEAL_MUTATION } from 'features/loanPool/PoolHeader';
import {
  CreateDeal,
  CreateDealVariables,
} from 'features/loanPool/__generated__/CreateDeal';
import { ListingSidebarListing } from './__generated__/ListingSidebarListing';
import { useGet2ndLevelPageUrl } from 'app-level/navigation';
import { useNavigate } from 'react-router-dom';
import { styled } from 'style/ORSNNTheme';
import { Dialog, useDialog } from 'common-ui/Dialog';

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ListingSidebarFragments = {
  listing: gql`
    fragment ListingSidebarListing on Listing {
      id
      name
      in_progress_deal_id
      company {
        __typename
      }
    }
  `,
};

type ListingSidebarProps = {
  listing: ListingSidebarListing;
};

const PlainDiv = styled.div``;

const ListingSidebar = (props: ListingSidebarProps): JSX.Element => {
  const getUrl = useGet2ndLevelPageUrl();
  const [createDeal] = useMutation<CreateDeal, CreateDealVariables>(
    CREATE_DEAL_MUTATION
  );
  const navigate = useNavigate();

  const acceptDialog = useDialog();

  const needAccess =
    props.listing.company.__typename !== 'UserCompany' &&
    !props.listing.in_progress_deal_id;

  const handleAccept = () => {
    acceptDialog.closeDialog();

    createDeal({ variables: { listingId: props.listing.id } }).then(
      (response) => {
        const dealId = response.data?.createDeal.id;
        if (dealId) {
          navigate(getUrl('marketplace', 'deal'), { state: { dealId } });
        }
      }
    );
  };

  return (
    <>
      {needAccess ? (
        <div className="progress">
          <BaseButton
            type="secondary"
            size="medium"
            label="Create Offering"
            onClick={() => acceptDialog.openDialog()}
          >
            Request Access
          </BaseButton>
        </div>
      ) : null}
      <Dialog dialog={acceptDialog} contentComponent={PlainDiv}>
        <div className="modal__container">
          <div className="modal__header_container">
            <div className="modal__header_text">Request Access</div>
            <div className="modal__header_subtext">ACCESS</div>
            <div className="modal__header_caption">
              By requesting access, you are asking the company for permission
              to access this offering. You will share your identity with the
              request.
            </div>
          </div>
          <div className="modal__body">
            <ModalButtonsContainer>
              <BaseButton type="primary" label="Accept" onClick={handleAccept}>
                Accept
              </BaseButton>
              <BaseButton
                type="secondary"
                label="Decline"
                onClick={() => acceptDialog.closeDialog()}
              >
                Decline
              </BaseButton>
            </ModalButtonsContainer>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ListingSidebar;
export { ListingSidebarFragments };

