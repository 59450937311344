type Guard<T> = (value: unknown) => value is T;
const isString: Guard<string> = (value: unknown): value is string => typeof value === 'string';
const isNumber: Guard<number> = (value: unknown): value is number => typeof value === 'number' && value - value === 0;
const isBoolean: Guard<boolean> = (value: unknown): value is boolean => typeof value === 'boolean';

const isRecordOf = <A>(itemGuard: Guard<A>): Guard<Record<string, A>> =>
  (x: unknown): x is Record<string, A> =>
    typeof x === 'object' && x !== null && Object.values(x).every(itemGuard);
const isArrayOf = <A>(itemGuard: Guard<A>): Guard<Array<A>> =>
  (x: unknown): x is A[] =>
    Array.isArray(x) && x.every(itemGuard)

type PropertyGuards<A extends object> = { [K in keyof A]: Guard<A[K]> }

const isType = <A extends object>(propertyGuards: PropertyGuards<A>): Guard<A> =>
  (x: unknown): x is A =>
    typeof x === 'object' && x !== null && Object.entries<Guard<A[keyof A]>>(propertyGuards).every(([k, guard]) => guard((x as A)[k as keyof A]));

function isNotNullOrUndefined<T>(val?: T | null): val is NonNullable<T> {
  return val !== null && typeof val !== 'undefined' && val !== undefined;
}

function getStringAsKeyOf<T extends object>(obj: T, key: string | undefined): keyof T | null {
  if (!key) {
    return null;
  }
  if (key in obj) {
    return key as keyof T;
  }
  return null;
}

export {
  isBoolean,
  isString,
  isNumber,
  isRecordOf,
  isArrayOf,
  isType,
  isNotNullOrUndefined,
  getStringAsKeyOf,
};
